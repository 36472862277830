"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProfileUpdate = exports.DEFAULT_FORM = void 0;
const user_hooks_1 = require("@hooks/user.hooks");
const react_1 = require("react");
exports.DEFAULT_FORM = {
    firstName: "",
    lastName: "",
    email: "",
    avatar: "",
    twitter: "",
    instagram: "",
    telegram: "",
    description: "",
};
const useProfileUpdate = (account) => {
    const updateUser = (0, user_hooks_1.useUserUpdate)();
    return (0, react_1.useCallback)((form) => updateUser(Object.assign({}, form)), [account]);
};
exports.useProfileUpdate = useProfileUpdate;
