"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimer = void 0;
const interval_hooks_1 = require("@shared-modules/utils/hooks/interval.hooks");
const react_1 = require("react");
const useTimer = (startFrom, left, onZero) => {
    const [currentSecs] = (0, interval_hooks_1.useInterval)(1000);
    const [isZeroTriggered, setIsZeroTriggered] = (0, react_1.useState)(false);
    const secsPassed = Math.ceil(currentSecs - startFrom);
    const secs = (typeof left === "function" ? left() : left) - secsPassed;
    (0, react_1.useEffect)(() => {
        if (secs === -1 && !isZeroTriggered && onZero) {
            onZero();
            setIsZeroTriggered(true);
        }
        else if (secs > 0 && isZeroTriggered) {
            setIsZeroTriggered(false);
        }
    }, [secs, onZero]);
    return secs;
};
exports.useTimer = useTimer;
