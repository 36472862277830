"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withListenerProvider = exports.withListener = exports.useListener = exports.ListenerContext = exports.AppListener = exports.EventContainer = void 0;
const react_1 = __importStar(require("react"));
class EventContainer {
    constructor(name) {
        this.subscribers = [];
        this.hasAnySubscriber = () => Boolean(this.subscribers.length);
        this.addSubscriber = (func) => {
            this.subscribers.push(func);
        };
        this.removeSubscriber = (removeFunc) => {
            this.subscribers = this.subscribers.filter((func) => func !== removeFunc);
        };
        this.initSubscribers = (data) => {
            this.subscribers.forEach((subscriber) => subscriber(data));
        };
        this.name = name;
    }
}
exports.EventContainer = EventContainer;
class AppListener {
    constructor() {
        this.eventContainers = [];
        this.addListener = (name, func) => {
            const eventContainer = this.getContainer(name);
            if (eventContainer) {
                eventContainer.addSubscriber(func);
            }
            else {
                this.createContainer(name, func);
            }
            return () => this.removeListener(name, func);
        };
        this.removeListener = (name, func) => {
            const eventContainer = this.getContainer(name);
            if (eventContainer) {
                eventContainer.removeSubscriber(func);
            }
        };
        this.hasAnySubscriber = (name) => {
            var _a;
            return Boolean((_a = this.getContainer(name)) === null || _a === void 0 ? void 0 : _a.hasAnySubscriber());
        };
        this.notifyListener = (name, data) => {
            const eventContainer = this.getContainer(name);
            if (eventContainer) {
                eventContainer.initSubscribers(data);
            }
        };
        this.removeContainer = (name) => {
            const eventContainer = this.getContainer(name);
            if (eventContainer) {
                this.eventContainers.splice(this.eventContainers.indexOf(eventContainer), 1);
            }
        };
        this.getContainer = (name) => {
            return this.eventContainers.find((container) => container.name === name);
        };
        this.createContainer = (name, func) => {
            const container = new EventContainer(name);
            container.addSubscriber(func);
            this.eventContainers.push(container);
        };
    }
}
exports.AppListener = AppListener;
exports.ListenerContext = react_1.default.createContext(new AppListener());
const useListener = () => (0, react_1.useContext)(exports.ListenerContext);
exports.useListener = useListener;
const withListener = (Component) => (props) => (react_1.default.createElement(exports.ListenerContext.Consumer, null, (container) => {
    const { addListener, removeListener, notifyListener, removeContainer, hasAnySubscriber } = container;
    const newProps = Object.assign(Object.assign({}, props), { addListener,
        notifyListener,
        removeListener,
        removeContainer,
        hasAnySubscriber });
    return react_1.default.createElement(Component, Object.assign({}, newProps));
}));
exports.withListener = withListener;
const withListenerProvider = (WrappedComponent) => (props) => {
    const container = new AppListener();
    const { addListener, removeListener, notifyListener, removeContainer, hasAnySubscriber } = container;
    const newProps = Object.assign(Object.assign({}, props), { addListener,
        removeListener,
        notifyListener,
        removeContainer,
        hasAnySubscriber });
    return (react_1.default.createElement(exports.ListenerContext.Provider, { value: container },
        react_1.default.createElement(WrappedComponent, Object.assign({}, newProps))));
};
exports.withListenerProvider = withListenerProvider;
