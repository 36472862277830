"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LotteryCard = void 0;
const React = __importStar(require("react"));
const core_1 = require("@web3-react/core");
const button_1 = require("@shared-modules/components/button");
const number_1 = require("@utils/object/number");
const object_1 = require("@utils/object");
const base_card_1 = require("../base-card");
require("./styles.scss");
const user_mini_profile_1 = require("@components/user-mini-profile");
const react_router_dom_1 = require("react-router-dom");
const translation_hooks_1 = require("@hooks/translation.hooks");
const LotteryCard = (props) => {
    const { lottery, onClick, isOwner = false, tokenPageUrl } = props;
    const translator = (0, translation_hooks_1.useComponentTranslation)("lotteryCard");
    const { endLotteryDate, tickets, token, soldTickets, totalTickets } = lottery;
    const { account } = (0, core_1.useWeb3React)();
    const secLeft = (0, object_1.getTimeStampInSec)(endLotteryDate) - (0, object_1.getTimeStampInSec)();
    const myTicketsAmount = tickets.filter(({ account: buyer }) => (0, object_1.caseInsensitiveCompare)(buyer, account)).length;
    const shortDuration = (0, object_1.maxDurationGranule)(secLeft);
    return (React.createElement(base_card_1.BaseCard, { onPreviewClick: onClick, baseMetaDataUrl: token.metaUrlBase, metadata: token.metadata, tokenPageUrl: tokenPageUrl },
        React.createElement("div", { className: "c-lottery-card" },
            React.createElement("div", { className: "c-lottery-card__alert" }, shortDuration.value > 0
                ? `${translator("leftTimeLabel")} ${shortDuration.value} ${shortDuration.title}`
                : translator("endedLabel")),
            React.createElement("div", { className: "c-lottery-card__title" }, token.name),
            React.createElement("div", { className: "c-lottery-card__info" },
                React.createElement("div", { className: "c-lottery-card__info-section" },
                    React.createElement("div", { className: "c-lottery-card__info-section-label" },
                        translator("soldOutLabel"),
                        " / ",
                        translator("myTicketsLabel")),
                    React.createElement("div", { className: "c-lottery-card__info-section-value" },
                        (0, number_1.formatBigNumber)((soldTickets / totalTickets) * 100),
                        "% / ",
                        myTicketsAmount)),
                React.createElement("div", { className: "c-lottery-card__info-section" },
                    React.createElement("div", { className: "c-lottery-card__info-section-label" }, translator("collectionNameLabel")),
                    React.createElement(react_router_dom_1.Link, { to: `/collections/${token.collectionId}`, className: "g-link c-lottery-card__info-section-value" }, token.collection.title))),
            React.createElement("div", { className: "c-lottery-card__footer" },
                React.createElement(user_mini_profile_1.UserMiniProfile, { title: translator("ownerLabel"), avatar: token.owner.avatar, displayName: (0, object_1.concatStringsWithWhitespace)(token.owner.firstName, token.owner.lastName), address: token.owner.wallet }),
                React.createElement(button_1.Button, { tag: "a", buttonUrl: tokenPageUrl, size: "small" }, isOwner ? translator("manageButton") : translator("buyTicketsButton"))))));
};
exports.LotteryCard = LotteryCard;
