"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardVideoPlayer = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const icons_1 = require("@shared-modules/components/icons");
require("./styles.scss");
const CardVideoPlayer = (props) => {
    const { src, preview, contentClassName, onPause, onPlay } = props;
    const videoRef = (0, react_1.useRef)(null);
    const [isPreviewVisible, setIsPreviewVisible] = (0, react_1.useState)(true);
    const [isVideoStarted, setIsVideoStarted] = (0, react_1.useState)(false);
    const switchPlayState = (event) => {
        var _a, _b;
        event.stopPropagation();
        event.preventDefault();
        if (!isVideoStarted) {
            (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.play();
            setIsPreviewVisible(false);
            setIsVideoStarted(true);
            onPlay();
        }
        else {
            onPause();
            (_b = videoRef.current) === null || _b === void 0 ? void 0 : _b.pause();
            setIsVideoStarted(false);
        }
    };
    const resetVideo = () => {
        setIsVideoStarted(false);
        setIsPreviewVisible(true);
        onPause();
    };
    const contentClass = contentClassName ? contentClassName : "";
    const imageClassNames = `c-card-video-player__preview ${contentClass}`;
    const videoClassNames = `c-card-video-player__video ${isPreviewVisible ? "c-card-video-player__video-hidden" : ""} ${contentClass}`;
    return (React.createElement("div", { className: "c-card-video-player" },
        preview && isPreviewVisible && React.createElement("img", { className: imageClassNames, src: preview }),
        React.createElement("video", { ref: videoRef, onEnded: resetVideo, className: videoClassNames, muted: true, playsInline: true, src: src, preload: "metadata" }, "Your browser does not support the video tag."),
        React.createElement("div", { onClick: switchPlayState, className: `c-card-video-player__control c-card-video-player__control--${isVideoStarted ? "play" : ""}` }, isVideoStarted ? React.createElement(icons_1.PauseIcon, { scale: 0.8 }) : React.createElement(icons_1.PlayIcon, { scale: 0.8 }))));
};
exports.CardVideoPlayer = CardVideoPlayer;
