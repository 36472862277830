"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTokenBurning = exports.useTokenTransfer = void 0;
const translation_hooks_1 = require("@hooks/translation.hooks");
const core_1 = require("@web3-react/core");
const react_1 = require("react");
const contract_hooks_1 = require("./contract.hooks");
const useTokenTransfer = (tokenId, tokenAddress) => {
    const { account } = (0, core_1.useWeb3React)();
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCNFT", tokenAddress, "transferFrom");
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    return (0, react_1.useCallback)((toAccount) => __awaiter(void 0, void 0, void 0, function* () {
        const summary = translator("transferFrom", {
            toAccount,
            tokenId,
        });
        const promiseCallback = yield commandExecutor(summary, [account, toAccount, tokenId], tokenId);
        return promiseCallback();
    }), [commandExecutor, tokenId, tokenAddress]);
};
exports.useTokenTransfer = useTokenTransfer;
const useTokenBurning = (tokenId, tokenAddress) => {
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCNFT", tokenAddress, "burn");
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        const summary = translator("burn", {
            tokenId,
        });
        const promiseCallback = yield commandExecutor(summary, [tokenId], tokenId);
        return promiseCallback();
    }), [commandExecutor, tokenId, tokenAddress]);
};
exports.useTokenBurning = useTokenBurning;
