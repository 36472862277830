"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileSidebar = void 0;
const react_router_dom_1 = require("react-router-dom");
const React = __importStar(require("react"));
require("./styles.scss");
const identity_reducer_1 = require("@app/store/reducers/identity.reducer");
const translation_hooks_1 = require("@hooks/translation.hooks");
const getProfileRoute = (account, translator) => [
    {
        name: translator("createNftLink"),
        allow: identity_reducer_1.AppRole.deny,
        route: "/nft/create",
        pattern: new RegExp("/nft/create"),
    },
    {
        name: translator("portfolio"),
        route: `/profile${account ? "/" + account : ""}`,
        pattern: new RegExp("/profile(/(0x[a-fA-F0-9]{40})|)$"),
    },
    {
        name: translator("collections"),
        pattern: new RegExp("/profile(/(0x[a-fA-F0-9]{40})|)/collections$"),
        route: `/profile/${account ? account + "/" : ""}collections`,
    },
    {
        name: translator("stats"),
        pattern: new RegExp("/profile(/(0x[a-fA-F0-9]{40})|)/stats$"),
        route: `/profile/${account ? account + "/" : ""}stats`,
    },
    {
        name: translator("ref"),
        pattern: new RegExp("/profile(/(0x[a-fA-F0-9]{40})|)/ref$"),
        route: `/profile/${account ? account + "/" : ""}ref`,
    },
];
const ProfileSidebar = (props) => {
    const { account, isOwner } = props;
    const { pathname } = (0, react_router_dom_1.useLocation)();
    const isInRoleHandler = (0, identity_reducer_1.useInRoleLazy)();
    const translator = (0, translation_hooks_1.useComponentTranslation)("profileSidebar");
    const routes = getProfileRoute(isOwner ? "" : account, translator);
    return (React.createElement("aside", { className: "c-profile-sidebar" }, routes
        .filter(({ allow }) => (allow ? isInRoleHandler(allow, true) && isOwner : true))
        .map(({ name, route, pattern }) => (React.createElement("div", { key: name, className: `c-profile-sidebar__item ${pathname.match(pattern) ? "c-profile-sidebar__item--active" : ""}` },
        React.createElement(react_router_dom_1.Link, { to: route, className: `c-profile-sidebar__link ${pathname.match(pattern) ? "c-profile-sidebar__link--active" : ""}` }, name))))));
};
exports.ProfileSidebar = ProfileSidebar;
