"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCollectionNames = exports.useCollectionRating = exports.useCollectionStats = exports.useCollectionById = exports.useCollections = exports.useUpdateCollection = exports.useCreatorCollection = void 0;
const auth_service_1 = require("@app/services/auth.service");
const collection_service_1 = require("@app/services/collection.service");
const core_1 = require("@web3-react/core");
const react_1 = require("react");
const data_loader_hooks_1 = require("./data-loader.hooks");
const logger_hooks_1 = require("./logger.hooks");
const authService = new auth_service_1.AuthService();
const collectionService = new collection_service_1.CollectionService();
const useCreatorCollection = () => {
    const { library, account } = (0, core_1.useWeb3React)();
    const errorHandler = (0, logger_hooks_1.useErrorMessageByCode)();
    return (0, react_1.useCallback)((title, description, coverUrl, previewUrl) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected);
            return;
        }
        let signature = "";
        try {
            signature = yield authService.getJwt(library, account);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected, error);
        }
        try {
            yield collectionService.createCollection(title, description, coverUrl, previewUrl, account, signature);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.ApiRequestError, error);
        }
    }), [library, account]);
};
exports.useCreatorCollection = useCreatorCollection;
const useUpdateCollection = (id) => {
    const { library, account } = (0, core_1.useWeb3React)();
    const errorHandler = (0, logger_hooks_1.useErrorMessageByCode)();
    return (0, react_1.useCallback)((title, description, coverUrl, previewUrl) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected);
            return;
        }
        let signature = "";
        try {
            signature = yield authService.getJwt(library, account);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected, error);
        }
        try {
            yield collectionService.updateCollection(id, title, description, coverUrl, previewUrl, account, signature);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.ApiRequestError, error);
        }
    }), [library, account]);
};
exports.useUpdateCollection = useUpdateCollection;
const useCollections = (filter, state) => (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => collectionService.getCollections(filter, page, size), [JSON.stringify(filter)], Object.assign({ page: 1, size: 10 }, state));
exports.useCollections = useCollections;
const useCollectionById = (id) => (0, data_loader_hooks_1.useDataLoader)(() => collectionService.getCollectionById(id), [id], undefined);
exports.useCollectionById = useCollectionById;
const useCollectionStats = (id) => (0, data_loader_hooks_1.useDataLoader)(() => __awaiter(void 0, void 0, void 0, function* () { return yield collectionService.getCollectionStats(id); }), [id], {
    tradeVolume: [],
    tradedTokensCount: 0,
});
exports.useCollectionStats = useCollectionStats;
const useCollectionRating = (period, state) => (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => collectionService.getRating(period, page, size), [period || "no-period"], Object.assign({ page: 1, size: 10, preSave: false }, state));
exports.useCollectionRating = useCollectionRating;
const useCollectionNames = () => (0, data_loader_hooks_1.useDataLoader)(() => collectionService.getCollectionNames(), [], []);
exports.useCollectionNames = useCollectionNames;
