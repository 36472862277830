"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const validator_1 = require("@app/abi/validator");
const local_store_service_1 = require("@app/services/local-store.service");
const react_router_dom_1 = require("react-router-dom");
const ReferralPage = () => {
    const { params: { refAddress }, } = (0, react_router_dom_1.useRouteMatch)();
    const history = (0, react_router_dom_1.useHistory)();
    const storage = new local_store_service_1.LocalStoreService();
    if (!storage.hasReferral() && refAddress.match(validator_1.ADDRESS_REGEX)) {
        storage.setReferral(refAddress);
    }
    history.push("/");
    return null;
};
exports.default = ReferralPage;
