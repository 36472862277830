"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTokenByAddress = exports.useTokenGetter = exports.useTokenList = exports.useTokenListForCurrentChain = exports.setTokeList = exports.tokensSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const object_1 = require("@utils/object");
const core_1 = require("@web3-react/core");
const react_1 = require("react");
const hooks_1 = require("../hooks");
const initialState = {
    list: [],
};
exports.tokensSlice = (0, toolkit_1.createSlice)({
    name: "tokens",
    initialState,
    reducers: {
        setTokeList: (state, action) => {
            state.list = action.payload;
        },
    },
});
exports.setTokeList = exports.tokensSlice.actions.setTokeList;
const useTokenListForCurrentChain = () => {
    const context = (0, core_1.useWeb3React)();
    return (0, hooks_1.useAppSelector)((state) => state.tokens.list.filter(({ chainId }) => chainId === context.chainId));
};
exports.useTokenListForCurrentChain = useTokenListForCurrentChain;
const useTokenList = () => (0, hooks_1.useAppSelector)((state) => state.tokens.list);
exports.useTokenList = useTokenList;
const useTokenGetter = () => {
    const tokenList = (0, hooks_1.useAppSelector)((state) => state.tokens.list);
    return (0, react_1.useCallback)((address) => tokenList.find((token) => (0, object_1.caseInsensitiveCompare)(address, token.address)), []);
};
exports.useTokenGetter = useTokenGetter;
const useTokenByAddress = (address) => {
    return (0, hooks_1.useAppSelector)((state) => state.tokens.list.find((token) => (0, object_1.caseInsensitiveCompare)(address, token.address)));
};
exports.useTokenByAddress = useTokenByAddress;
exports.default = exports.tokensSlice.reducer;
