"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceHistory = void 0;
const models_1 = require("@app/services/models");
const React = __importStar(require("react"));
const react_chartjs_2_1 = require("react-chartjs-2");
const token_hooks_1 = require("@hooks/token.hooks");
const tokens_reducer_1 = require("@app/store/reducers/tokens.reducer");
const number_1 = require("@utils/object/number");
const lodash_1 = require("lodash");
const EVENTS = [models_1.EventType.auctionBid, models_1.EventType.auctionBuyNow, models_1.EventType.sell];
const YEAR = 365 * 24 * 3600 * 1000;
const OPTIONS = {
    responsive: true,
    scales: {
        y: {
            min: 0,
            max: undefined,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
};
const PriceHistory = (props) => {
    const { nft, symbol } = props;
    const { list } = (0, token_hooks_1.useTokenHistory)(nft.tokenId, {
        events: EVENTS,
        fromDate: new Date().getTime() - YEAR,
        sorting: "asc",
    }, 1000);
    const tokenGetter = (0, tokens_reducer_1.useTokenGetter)();
    const pricesHistory = list
        .filter((record) => { var _a; return ((_a = tokenGetter(record.paymentTokenAddress)) === null || _a === void 0 ? void 0 : _a.symbol) === symbol; })
        .map(({ price = "0", date, paymentTokenAddress }) => {
        var _a;
        return [
            date,
            (0, number_1.normalizeValue)(price, (_a = tokenGetter(paymentTokenAddress)) === null || _a === void 0 ? void 0 : _a.decimals).toNumber(),
        ];
    });
    const data = React.useMemo(() => {
        return {
            labels: pricesHistory.map(() => ""),
            datasets: [
                {
                    label: "",
                    data: pricesHistory.map(([, price]) => price),
                    borderColor: "rgb(255, 0, 0)",
                    tension: 0.5,
                },
            ],
        };
    }, [(0, lodash_1.flatMap)(pricesHistory).join()]);
    return React.createElement(react_chartjs_2_1.Line, { key: JSON.stringify(data), redraw: true, height: "40px", width: "100%", options: OPTIONS, data: data });
};
exports.PriceHistory = PriceHistory;
