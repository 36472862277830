"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidators = exports.DEFAULT_FROM = void 0;
const validators_1 = require("@hooks/forms/validators");
exports.DEFAULT_FROM = {
    code: "",
};
const getValidators = (code, translator) => ({
    code: [
        (0, validators_1.createRequiredValidator)(translator("validation.required")),
        (0, validators_1.createEqualValidator)(code, translator("validation.notEqual")),
    ],
});
exports.getValidators = getValidators;
