"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWalletPopupStateToggle = exports.useLeftHandNavToggle = exports.useWalletPopupState = exports.useLeftHandNavState = exports.useTranslations = exports.useSelectedLanguage = exports.setLanguage = exports.setTranslations = exports.toggleWalletPopup = exports.toggleMenu = exports.applicationStateSlice = void 0;
const local_store_service_1 = require("@app/services/local-store.service");
const toolkit_1 = require("@reduxjs/toolkit");
const hooks_1 = require("../hooks");
const initialState = {
    isLHNOpened: false,
    isWalletPopupOpened: false,
    selectedLanguage: new local_store_service_1.LocalStoreService().getSelectedLanguageOrDefault(),
    translations: undefined,
};
exports.applicationStateSlice = (0, toolkit_1.createSlice)({
    name: "applicationState",
    initialState,
    reducers: {
        toggleMenu: (state, action) => {
            state.isLHNOpened = action.payload.isLHNOpened;
        },
        toggleWalletPopup: (state, action) => {
            state.isWalletPopupOpened = action.payload.isWalletPopupOpened;
        },
        setTranslations: (state, action) => {
            state.translations = action.payload;
        },
        setLanguage: (state, action) => {
            state.selectedLanguage = action.payload;
        },
    },
});
_a = exports.applicationStateSlice.actions, exports.toggleMenu = _a.toggleMenu, exports.toggleWalletPopup = _a.toggleWalletPopup, exports.setTranslations = _a.setTranslations, exports.setLanguage = _a.setLanguage;
const useSelectedLanguage = () => (0, hooks_1.useAppSelector)((state) => state.applicationState.selectedLanguage);
exports.useSelectedLanguage = useSelectedLanguage;
const useTranslations = () => (0, hooks_1.useAppSelector)((state) => state.applicationState.translations);
exports.useTranslations = useTranslations;
const useLeftHandNavState = () => (0, hooks_1.useAppSelector)((state) => state.applicationState.isLHNOpened);
exports.useLeftHandNavState = useLeftHandNavState;
const useWalletPopupState = () => (0, hooks_1.useAppSelector)((state) => state.applicationState.isWalletPopupOpened);
exports.useWalletPopupState = useWalletPopupState;
const useLeftHandNavToggle = () => {
    const dispatch = (0, hooks_1.useAppDispatch)();
    return (isOpened) => dispatch((0, exports.toggleMenu)({ isLHNOpened: isOpened }));
};
exports.useLeftHandNavToggle = useLeftHandNavToggle;
const useWalletPopupStateToggle = () => {
    const dispatch = (0, hooks_1.useAppDispatch)();
    return (isOpened) => dispatch((0, exports.toggleWalletPopup)({ isWalletPopupOpened: isOpened }));
};
exports.useWalletPopupStateToggle = useWalletPopupStateToggle;
exports.default = exports.applicationStateSlice.reducer;
