"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserRefStats = exports.useUserUpdate = exports.useCreatorRequest = exports.useCreatorReject = exports.useCreatorList = exports.useUserList = exports.useRefUsersRating = exports.useUsersRating = exports.useCurrentUser = exports.useUserStatsByAccount = exports.useUserByAccount = void 0;
const core_1 = require("@web3-react/core");
const react_1 = require("react");
const auth_service_1 = require("../services/auth.service");
const user_service_1 = require("../services/user.service");
const logger_hooks_1 = require("./logger.hooks");
const data_loader_hooks_1 = require("../hooks/data-loader.hooks");
const userService = new user_service_1.UserService();
const authService = new auth_service_1.AuthService();
const useUserByAccount = (account) => (0, data_loader_hooks_1.useDataLoader)(() => __awaiter(void 0, void 0, void 0, function* () {
    if (account) {
        return yield userService.getUserByAccount(account);
    }
}), [account], undefined);
exports.useUserByAccount = useUserByAccount;
const useUserStatsByAccount = (account) => (0, data_loader_hooks_1.useDataLoader)(() => __awaiter(void 0, void 0, void 0, function* () {
    if (account) {
        return yield userService.getUserStatsByAccount(account);
    }
}), [account], undefined);
exports.useUserStatsByAccount = useUserStatsByAccount;
const useCurrentUser = () => {
    const { account } = (0, core_1.useWeb3React)();
    return (0, data_loader_hooks_1.useDataLoader)(() => __awaiter(void 0, void 0, void 0, function* () {
        if (account) {
            return yield userService.getUserByAccount(account);
        }
    }), [account], undefined);
};
exports.useCurrentUser = useCurrentUser;
const useUsersRating = (period, state) => (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => userService.getRating(period, page, size), [period || "no-period"], Object.assign({ page: 1, size: 10, preSave: false }, state));
exports.useUsersRating = useUsersRating;
const useRefUsersRating = (referrer, period, state) => (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => userService.getRefRating(referrer, period, page, size), [period || "no-period"], Object.assign({ page: 1, size: 10, preSave: false }, state));
exports.useRefUsersRating = useRefUsersRating;
const useUserList = (state) => (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => userService.getUserList(page, size), [], Object.assign({ page: 1, size: 10 }, state));
exports.useUserList = useUserList;
const useCreatorList = (state) => (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => userService.getCreators(page, size), [], Object.assign({ preSave: false, page: 1, size: 10 }, state));
exports.useCreatorList = useCreatorList;
const useCreatorReject = () => {
    const { library, account } = (0, core_1.useWeb3React)();
    const errorHandler = (0, logger_hooks_1.useErrorMessageByCode)();
    return (0, react_1.useCallback)((rejectedAccount) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected);
            return;
        }
        let signature = "";
        try {
            signature = yield authService.getJwt(library, account);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected, error);
        }
        try {
            yield userService.rejectCreator(rejectedAccount, signature);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.ApiRequestError, error);
        }
    }), [library, account]);
};
exports.useCreatorReject = useCreatorReject;
const useCreatorRequest = () => {
    const { library, account } = (0, core_1.useWeb3React)();
    const errorHandler = (0, logger_hooks_1.useErrorMessageByCode)();
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected);
            return;
        }
        let signature = "";
        try {
            signature = yield authService.getJwt(library, account);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected, error);
        }
        try {
            yield userService.requestCreator(account, signature);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.ApiRequestError, error);
        }
    }), [library, account]);
};
exports.useCreatorRequest = useCreatorRequest;
const useUserUpdate = () => {
    const { library, account } = (0, core_1.useWeb3React)();
    const errorHandler = (0, logger_hooks_1.useErrorMessageByCode)();
    return (0, react_1.useCallback)((user) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected);
            return;
        }
        let signature = "";
        try {
            signature = yield authService.getJwt(library, account);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected, error);
        }
        try {
            yield userService.updateProfile(account, user, signature);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.ApiRequestError, error);
        }
    }), [library, account]);
};
exports.useUserUpdate = useUserUpdate;
const useUserRefStats = () => {
    const { account } = (0, core_1.useWeb3React)();
    return (0, data_loader_hooks_1.useDataLoader)(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return undefined;
        }
        return yield userService.getUserRefStats(account);
    }), [account], {
        referralsCount: 0,
    });
};
exports.useUserRefStats = useUserRefStats;
