"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpService = void 0;
const http_1 = require("@utils/http");
class HttpService {
    constructor(baseUrl) {
        this.attachJwt = (jwt, headers = new Headers()) => {
            headers.append("Authorization", `Bearer ${jwt}`);
            return headers;
        };
        this.httpClient = new http_1.HttpClient(baseUrl, ({ message }) => message);
    }
}
exports.HttpService = HttpService;
