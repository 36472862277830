"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpOperationError = void 0;
const base_error_1 = require("./base-error");
class HttpOperationError extends base_error_1.BaseError {
    constructor(url, message, context, parentError) {
        super(message, context, parentError);
        this.name = "HttpOperationError";
        this.url = url;
    }
}
exports.HttpOperationError = HttpOperationError;
