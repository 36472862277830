"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useErrorMessageByCode = exports.ErrorCode = void 0;
const react_1 = require("react");
var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["NotConnected"] = 1] = "NotConnected";
    ErrorCode[ErrorCode["SignatureApprove"] = 2] = "SignatureApprove";
    ErrorCode[ErrorCode["ApiRequestError"] = 3] = "ApiRequestError";
    ErrorCode[ErrorCode["CreateTokenError"] = 4] = "CreateTokenError";
    ErrorCode[ErrorCode["GettingAuctionBidVoucherError"] = 4] = "GettingAuctionBidVoucherError";
    ErrorCode[ErrorCode["GettingLotteryWinnerVoucherError"] = 4] = "GettingLotteryWinnerVoucherError";
    ErrorCode[ErrorCode["GettingTokenVoucherError"] = 4] = "GettingTokenVoucherError";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));
const useErrorMessageByCode = () => (0, react_1.useCallback)((code, error) => console.error(`Error code: ${code}`, error), []);
exports.useErrorMessageByCode = useErrorMessageByCode;
