"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserService = void 0;
const config_1 = require("@app/config");
const _dictionaries_1 = require("@dictionaries");
const http_1 = require("@utils/http");
const http_service_1 = require("./http.service");
class UserService extends http_service_1.HttpService {
    constructor() {
        super(config_1.CONFIG.baseApiUrl);
        this.getUserByAccount = (account) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getUserByAccountUrl(account));
            return object;
        });
        this.getUserStatsByAccount = (account) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getUserStatsByAccountUrl(account));
            return object;
        });
        this.getUserList = (page = 1, limit = 20) => __awaiter(this, void 0, void 0, function* () {
            const query = (0, http_1.parseToUrlQuery)({ page, limit }, true);
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getUsersUrl(query));
            return object;
        });
        this.rejectCreator = (account, jwt) => __awaiter(this, void 0, void 0, function* () {
            const headers = this.attachJwt(jwt);
            yield this.httpClient.delete(_dictionaries_1.API_ROUTES.rejectCreatorUrl(account), { headers });
        });
        this.requestCreator = (account, jwt) => __awaiter(this, void 0, void 0, function* () {
            const headers = this.attachJwt(jwt);
            yield this.httpClient.post(_dictionaries_1.API_ROUTES.requestCreatorUrl(account), { headers });
        });
        this.getCreators = (page = 1, limit = 20) => __awaiter(this, void 0, void 0, function* () {
            const query = (0, http_1.parseToUrlQuery)({ page, limit }, true);
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getCreatorsUrl(query));
            return object;
        });
        this.updateProfile = (account, user, jwt) => __awaiter(this, void 0, void 0, function* () {
            const headers = this.attachJwt(jwt);
            yield this.httpClient.patch(_dictionaries_1.API_ROUTES.updateUserUrl(account), { body: JSON.stringify(user), headers });
        });
        this.getUserRefStats = (account) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getUserRefStatsUrl(account));
            return object;
        });
        this.getRating = (period, page = 1, limit = 20) => __awaiter(this, void 0, void 0, function* () {
            const query = (0, http_1.parseToUrlQuery)({ page, limit, period }, true);
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getUserRating(query));
            return object;
        });
        this.getRefRating = (referrer, period, page = 1, limit = 20) => __awaiter(this, void 0, void 0, function* () {
            const query = (0, http_1.parseToUrlQuery)({ page, limit, period, referrer }, true);
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getRefRating(query));
            return object;
        });
    }
}
exports.UserService = UserService;
