"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createValidators = exports.DEFAULT_FORM = void 0;
const validators_1 = require("@hooks/forms/validators");
const object_1 = require("@utils/object");
exports.DEFAULT_FORM = {
    duration: "48",
    startDate: undefined,
    endDate: undefined,
    quantityCheck: "0",
    buyNowPrice: "",
    startPrice: "",
    auctionType: "duration",
    paymentToken: undefined,
};
const createValidators = (translator) => (form) => ({
    duration: form.auctionType === "duration" ? [(0, validators_1.createRequiredValidator)(translator("validation.required"))] : [],
    startDate: form.auctionType === "limit"
        ? [
            (0, validators_1.createRequiredValidator)(translator("validation.required")),
            (0, validators_1.createBeforeThanValidator)(form.endDate || new Date(), translator("validation.beforeDate", { boundary: (0, object_1.formatHumanDateTime)(form.endDate || new Date()) })),
        ]
        : [],
    endDate: form.auctionType === "limit"
        ? [
            (0, validators_1.createRequiredValidator)(translator("validation.required")),
            (0, validators_1.createAfterThanValidator)(form.startDate || new Date(), translator("validation.afterDate", { boundary: (0, object_1.formatHumanDateTime)(form.startDate || new Date()) })),
        ]
        : [],
    quantityCheck: [(0, validators_1.createRequiredValidator)(translator("validation.required"))],
    buyNowPrice: [(0, validators_1.createRequiredValidator)(translator("validation.required"))],
    startPrice: [
        (0, validators_1.createRequiredValidator)(translator("validation.required")),
        (0, validators_1.createLessThanValidator)(+(form.buyNowPrice || 0), translator("validation.lessThan", { boundary: form.buyNowPrice })),
    ],
    auctionType: [(0, validators_1.createRequiredValidator)(translator("validation.required"))],
    paymentToken: [(0, validators_1.createRequiredValidator)(translator("validation.required"))],
});
exports.createValidators = createValidators;
