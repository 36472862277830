"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBlockTracker = void 0;
const block_tracking_reducer_1 = require("@app/store/reducers/block-tracking.reducer");
const react_1 = require("react");
const useBlockTracker = (action) => {
    const [blockNumber, setBlock] = (0, react_1.useState)();
    const [isCompleted, setIsComplete] = (0, react_1.useState)(false);
    const lastSyncedBlock = (0, block_tracking_reducer_1.useLastSyncedBlock)();
    if (blockNumber !== undefined && lastSyncedBlock > blockNumber && !isCompleted) {
        setIsComplete(true);
        action(lastSyncedBlock);
    }
    return {
        waitBlock: setBlock,
    };
};
exports.useBlockTracker = useBlockTracker;
