"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultContractAction = void 0;
const approve_hooks_1 = require("@hooks/contracts/approve.hooks");
const translation_hooks_1 = require("@hooks/translation.hooks");
const button_1 = require("@shared-modules/components/button");
const react_1 = __importStar(require("react"));
require("./styles.scss");
const DefaultContractAction = (props) => {
    const { onApprove, actionHandler, approveState, actionName } = props;
    const [isActionInProgress, setIsActionInProgress] = (0, react_1.useState)(false);
    const commonTranslator = (0, translation_hooks_1.useCommonTranslation)();
    const approveHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield (onApprove === null || onApprove === void 0 ? void 0 : onApprove());
        }
        catch (error) {
            console.log(error);
        }
    });
    const submitHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsActionInProgress(true);
        try {
            yield actionHandler();
        }
        catch (error) {
            console.log(error);
        }
        setIsActionInProgress(false);
    });
    const isApproved = approveState === approve_hooks_1.ApprovalState.Approved;
    const isApproving = approveState === approve_hooks_1.ApprovalState.Pending;
    const isActionLocked = isActionInProgress || isApproving;
    const isApproveDisabled = isActionLocked || isApproved || approveState === approve_hooks_1.ApprovalState.Unknown;
    const isActionDisabled = (isActionLocked || !isApproved) && Boolean(onApprove);
    const isApproveLoading = approveState === approve_hooks_1.ApprovalState.Unknown || isApproving;
    return (react_1.default.createElement("div", { className: "c-default-contract-action", style: { gridTemplateColumns: !Boolean(onApprove) ? "1fr" : "1fr 1fr" } },
        onApprove && (react_1.default.createElement(button_1.Button, { loading: isApproveLoading, onClick: approveHandler, disabled: isApproveDisabled, type: "thirtery" }, commonTranslator("approveButton"))),
        react_1.default.createElement(button_1.Button, { disabled: isActionDisabled, loading: isActionInProgress, onClick: submitHandler }, actionName)));
};
exports.DefaultContractAction = DefaultContractAction;
