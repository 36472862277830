"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidOperationError = void 0;
const base_error_1 = require("./base-error");
class InvalidOperationError extends base_error_1.BaseError {
    constructor(message, context, parentError) {
        super(message, context, parentError);
        this.name = "InvalidOperationError";
    }
}
exports.InvalidOperationError = InvalidOperationError;
