"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOutsideClick = void 0;
const react_1 = require("react");
const useOutsideClick = (componentContainer, handler, deps = [], isFocused) => {
    const handleClickOutside = (e) => {
        var _a;
        if ((_a = componentContainer()) === null || _a === void 0 ? void 0 : _a.contains(e.target)) {
            return;
        }
        handler(e);
    };
    (0, react_1.useEffect)(() => {
        if (isFocused !== undefined) {
            if (isFocused) {
                document.removeEventListener("click", handleClickOutside);
                document.addEventListener("click", handleClickOutside);
            }
            else {
                document.removeEventListener("click", handleClickOutside);
            }
        }
        else {
            document.addEventListener("click", handleClickOutside);
        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, deps);
};
exports.useOutsideClick = useOutsideClick;
