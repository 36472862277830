"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wrapInTags = void 0;
const LINK_REGEX = new RegExp("(http|ftp|https)://([a-zA-Z0-9.-])([a-zA-Z0-9.,@?^=%&:/~+#-]*[a-zA-Z0-9@?^=%&/~+#-])", "g");
const wrapInTags = (text) => {
    let textWithHtml = text.replace(LINK_REGEX, (url) => `<a class="g-link" href="${url}" target="_blank" rel="noreferrer">${url}</a>`);
    textWithHtml = textWithHtml.replace(new RegExp("\n", "g"), "<br/>");
    return textWithHtml;
};
exports.wrapInTags = wrapInTags;
