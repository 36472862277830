"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartService = void 0;
const local_storage_1 = require("@utils/local-storage");
class CartService {
    constructor() {
        this.cartKey = "CART";
    }
    getCurrentCart() {
        var _a;
        return ((_a = local_storage_1.LocalStorage.getValue(this.cartKey)) === null || _a === void 0 ? void 0 : _a.cart) || [];
    }
    get count() {
        return;
    }
    setCart(cart) {
        local_storage_1.LocalStorage.updateValue(this.cartKey, { cart });
    }
}
exports.CartService = CartService;
