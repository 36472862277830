"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RatingPage = void 0;
const users_rating_grid_1 = require("@pages/rating-page/components/users-rating-grid");
const translation_hooks_1 = require("@hooks/translation.hooks");
const tabs_1 = require("@shared-modules/components/tabs");
const react_1 = __importStar(require("react"));
const collections_rating_grid_1 = require("./components/collections-rating-grid");
const rating_filters_1 = require("./components/rating-filters");
const helper_1 = require("./components/rating-filters/helper");
require("./styles.scss");
const getTabs = (translator) => [
    {
        label: translator("collectionsTab"),
        id: "collections",
    },
    {
        label: translator("userTabs"),
        id: "users",
    },
];
const RatingPage = (props) => {
    const [activePriceTab, setActivePriceTab] = (0, react_1.useState)("collections");
    const [filters, setFilters] = (0, react_1.useState)(helper_1.DEFAULT_FILTER_FORM);
    const translator = (0, translation_hooks_1.usePagesTranslation)("ratingPage");
    return (react_1.default.createElement("div", { className: "p-rating-page" },
        react_1.default.createElement("div", { className: "p-rating-page__tabs" },
            react_1.default.createElement(tabs_1.Tabs, { tabs: getTabs(translator), selectedTabId: activePriceTab, selectTab: setActivePriceTab })),
        react_1.default.createElement("div", { className: "p-rating-page__filters" },
            react_1.default.createElement(rating_filters_1.RatingFilters, { onFiltersChange: setFilters })),
        react_1.default.createElement("div", { className: "p-rating-page__section" },
            activePriceTab === "collections" && react_1.default.createElement(collections_rating_grid_1.CollectionsRatingGrid, { filters: filters }),
            activePriceTab === "users" && react_1.default.createElement(users_rating_grid_1.UsersRatingGrid, { filters: filters }))));
};
exports.RatingPage = RatingPage;
