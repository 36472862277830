"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCommonTranslation = exports.usePagesTranslation = exports.useComponentTranslation = exports.useTranslation = void 0;
const application_state_reducer_1 = require("@app/store/reducers/application-state.reducer");
const object_1 = require("@utils/object");
const useTranslation = (path) => {
    const translations = (0, application_state_reducer_1.useTranslations)();
    const componentTranslation = (0, object_1.getValueByPath)(translations, path);
    return (path, filler) => {
        let translation = (0, object_1.getValueByPath)(componentTranslation, path) || `{${path}}`;
        if (filler && typeof translation === "string") {
            Object.entries(filler).forEach(([key, value]) => {
                translation = translation.replace(`{${key}}`, value);
            });
        }
        return translation;
    };
};
exports.useTranslation = useTranslation;
const useComponentTranslation = (sectionName) => (0, exports.useTranslation)(`components.${sectionName}`);
exports.useComponentTranslation = useComponentTranslation;
const usePagesTranslation = (sectionName) => (0, exports.useTranslation)(`pages.${sectionName}`);
exports.usePagesTranslation = usePagesTranslation;
const useCommonTranslation = () => (0, exports.useTranslation)(`common`);
exports.useCommonTranslation = useCommonTranslation;
