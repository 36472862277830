"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHasPendingApproval = exports.isTransactionRecent = exports.useIsTransactionPending = exports.useAllTransactions = exports.useTransactionAdder = void 0;
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const core_1 = require("@web3-react/core");
const actions_1 = require("./actions");
const hooks_1 = require("../application/hooks");
// helper that can take a ethers library transaction response and add it to the list of transactions
function useTransactionAdder() {
    const { chainId, account } = (0, core_1.useWeb3React)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const walletInteractionSetter = (0, hooks_1.useSetWalletInteractions)();
    const watchTransaction = (0, react_1.useCallback)((response, { summary, approval, completeTrigger, } = {}) => {
        if (!account)
            return;
        if (!chainId)
            return;
        const { hash } = response;
        if (!hash) {
            throw Error("No transaction hash found.");
        }
        dispatch((0, actions_1.addTransaction)({
            hash,
            from: account,
            chainId,
            approval,
            summary,
            completeTrigger,
        }));
        walletInteractionSetter.setComplete(`tx/${hash}`);
    }, [chainId, account]);
    const handleTxError = (0, react_1.useCallback)((error) => {
        var _a;
        console.error(error);
        if (error.code === 4001)
            walletInteractionSetter.setError();
        else if ((_a = error.error) === null || _a === void 0 ? void 0 : _a.message)
            walletInteractionSetter.setError(error.error.message);
        else
            walletInteractionSetter.setNone();
    }, []);
    return [watchTransaction, handleTxError];
}
exports.useTransactionAdder = useTransactionAdder;
// returns all the transactions for the current chain
function useAllTransactions() {
    var _a;
    const { chainId } = (0, core_1.useWeb3React)();
    const state = (0, react_redux_1.useSelector)((state) => state.transactions);
    return chainId ? (_a = state[chainId]) !== null && _a !== void 0 ? _a : {} : {};
}
exports.useAllTransactions = useAllTransactions;
function useIsTransactionPending(transactionHash) {
    const transactions = useAllTransactions();
    if (!transactionHash || !transactions[transactionHash])
        return false;
    return !transactions[transactionHash].receipt;
}
exports.useIsTransactionPending = useIsTransactionPending;
/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
function isTransactionRecent(tx) {
    return new Date().getTime() - tx.addedTime < 86400000;
}
exports.isTransactionRecent = isTransactionRecent;
// returns whether a token has a pending approval transaction
function useHasPendingApproval(tokenAddress, spender) {
    const allTransactions = useAllTransactions();
    return (0, react_1.useMemo)(() => typeof tokenAddress === "string" &&
        typeof spender === "string" &&
        Object.keys(allTransactions).some((hash) => {
            const tx = allTransactions[hash];
            if (!tx)
                return false;
            if (tx.receipt) {
                return false;
            }
            else {
                const approval = tx.approval;
                if (!approval)
                    return false;
                return approval.spender === spender && approval.tokenAddress === tokenAddress && isTransactionRecent(tx);
            }
        }), [allTransactions, spender, tokenAddress]);
}
exports.useHasPendingApproval = useHasPendingApproval;
