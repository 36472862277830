"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRedirectToHandler = exports.useRedirectTo = void 0;
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useRedirectTo = (to, scrollToTop = false) => {
    const history = (0, react_router_dom_1.useHistory)();
    return (0, react_1.useCallback)((args) => {
        if (scrollToTop) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        history.push(typeof to === "function" ? to(args) : to);
    }, [to]);
};
exports.useRedirectTo = useRedirectTo;
const useRedirectToHandler = (to, scrollToTop = false) => {
    const redirectTo = (0, exports.useRedirectTo)(to, scrollToTop);
    return (0, react_1.useCallback)((args) => () => redirectTo(args), [to]);
};
exports.useRedirectToHandler = useRedirectToHandler;
