"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagerForm = void 0;
const identity_reducer_1 = require("@app/store/reducers/identity.reducer");
const button_1 = require("@shared-modules/components/button");
const popup_1 = require("@shared-modules/components/popup");
const React = __importStar(require("react"));
const translation_hooks_1 = require("@hooks/translation.hooks");
require("./styles.scss");
const create_auction_nft_form_1 = require("@components/create-auction-nft-form");
const create_lottery_nft_form_1 = require("@components/create-lottery-nft-form");
const create_sale_nft_form_1 = require("@components/create-sale-nft-form");
const block_tracker_hooks_1 = require("@hooks/block-tracker.hooks");
const react_1 = require("react");
const icons_1 = require("@shared-modules/components/icons");
const transfer_nft_form_1 = require("@components/transfer-nft-form");
const burn_nft_form_1 = require("@components/burn-nft-form");
const routing_hooks_1 = require("@hooks/routing.hooks");
const ManagerForm = (props) => {
    const { nft, updateToken } = props;
    const translator = (0, translation_hooks_1.usePagesTranslation)("tokenPage.managerTokenForm");
    const [popupType, setPopupType] = React.useState();
    const redirectProfile = (0, routing_hooks_1.useRedirectTo)("/profile", true);
    const { waitBlock } = (0, block_tracker_hooks_1.useBlockTracker)(() => {
        updateToken();
        setPopupType(undefined);
    });
    const { waitBlock: waitBurnTokenBlock } = (0, block_tracker_hooks_1.useBlockTracker)(() => {
        setPopupType(undefined);
        redirectProfile();
    });
    const closePopup = (0, react_1.useCallback)(() => {
        setPopupType(undefined);
    }, []);
    const finishOperation = (0, react_1.useCallback)((blockNumber) => {
        console.log(blockNumber);
        waitBlock(blockNumber);
    }, []);
    const burnTokenHandler = (0, react_1.useCallback)((blockNumber) => {
        waitBurnTokenBlock(blockNumber);
    }, []);
    const isMinter = (0, identity_reducer_1.useInRole)(identity_reducer_1.AppRole.minter);
    return (React.createElement("div", { className: "c-token-page-manager-form" },
        React.createElement("div", { className: "c-token-page-manager-form__grid" },
            React.createElement("div", { className: "c-token-page-manager-form__additional-info" },
                translator("royaltyFeeLabel"),
                React.createElement("div", { className: "c-token-page-manager-form__value" },
                    nft.token.royaltyFee,
                    "%"))),
        React.createElement("div", { className: "c-token-page-manager-form__buttons" },
            React.createElement(button_1.Button, { onClick: () => setPopupType("market"), type: "thirtery" }, translator("sellButton")),
            isMinter && (React.createElement(button_1.Button, { onClick: () => setPopupType("auction"), type: "thirtery" }, translator("createAuctionButton"))),
            isMinter && (React.createElement(button_1.Button, { onClick: () => setPopupType("lottery"), type: "thirtery" }, translator("createLotteryButton"))),
            React.createElement(button_1.Button, { onClick: () => setPopupType("transfer"), type: "thirtery" }, translator("transferToAccountButton")),
            React.createElement("div", { className: "c-token-page-manager-form__divide" }),
            React.createElement(button_1.Button, { onClick: () => setPopupType("burning"), type: "secondary" },
                React.createElement("div", { className: "c-token-page-manager-form__alert-button" },
                    React.createElement(icons_1.AlertIcon, null),
                    translator("burnTokenButton"),
                    React.createElement(icons_1.AlertIcon, null)))),
        React.createElement(popup_1.Popup, { isOpened: popupType === "auction", onClose: closePopup },
            React.createElement(create_auction_nft_form_1.CreateAuctionNFTForm, { onClose: finishOperation, token: nft.token })),
        React.createElement(popup_1.Popup, { isOpened: popupType === "market", onClose: closePopup },
            React.createElement(create_sale_nft_form_1.CreateSaleNFTForm, { onClose: finishOperation, token: nft.token })),
        React.createElement(popup_1.Popup, { isOpened: popupType === "lottery", onClose: closePopup },
            React.createElement(create_lottery_nft_form_1.CreateLotteryNFTForm, { onClose: finishOperation, token: nft.token })),
        React.createElement(popup_1.Popup, { isOpened: popupType === "transfer", onClose: closePopup },
            React.createElement(transfer_nft_form_1.TransferNFTForm, { onClose: finishOperation, token: nft.token })),
        React.createElement(popup_1.Popup, { isOpened: popupType === "burning", onClose: closePopup },
            React.createElement(burn_nft_form_1.BurnNFTForm, { onClose: burnTokenHandler, token: nft.token }))));
};
exports.ManagerForm = ManagerForm;
