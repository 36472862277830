"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenInfo = void 0;
const React = __importStar(require("react"));
require("./styles.scss");
const token_icon_1 = require("@shared-modules/components/token-icon");
const TokenInfo = (props) => {
    const { cells } = props;
    return (React.createElement("div", { className: "c-token-page-token-info" }, cells.map(({ label, currencySymbol, value, comment }) => {
        return (React.createElement("div", { key: label + currencySymbol + value + comment, className: "c-token-page-token-info__cell" },
            React.createElement("div", { className: "c-token-page-token-info__cell-label" }, label),
            React.createElement("div", { className: "c-token-page-token-info__cell-value" },
                currencySymbol && React.createElement(token_icon_1.TokenIcon, { symbol: currencySymbol, scale: 0.7 }),
                value,
                comment && React.createElement("span", { className: "c-token-page-token-info__cell-comment" }, comment))));
    })));
};
exports.TokenInfo = TokenInfo;
