"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const title_1 = require("@shared-modules/components/title");
require("./styles.scss");
const react_router_dom_1 = require("react-router-dom");
const token_hooks_1 = require("@hooks/token.hooks");
const react_1 = require("react");
const tabs_1 = require("@shared-modules/components/tabs");
const translation_hooks_1 = require("@hooks/translation.hooks");
const token_history_1 = require("./components/token-history");
const price_history_1 = require("./components/price-history");
const tokens_reducer_1 = require("@app/store/reducers/tokens.reducer");
const token_preview_1 = require("./components/token-preview");
const token_details_1 = require("./components/token-details");
const lodash_1 = require("lodash");
const withListener_1 = require("@app/hoc/withListener");
const block_tracker_hooks_1 = require("@hooks/block-tracker.hooks");
const _dictionaries_1 = require("@dictionaries");
const TokenPage = () => {
    var _a;
    const { params: { id: tokenId = "" }, } = (0, react_router_dom_1.useRouteMatch)();
    const translator = (0, translation_hooks_1.usePagesTranslation)("tokenPage");
    const { addListener } = (0, withListener_1.useListener)();
    const tokens = (0, tokens_reducer_1.useTokenList)();
    const tokenOptions = (0, lodash_1.uniq)(tokens.map(({ symbol }) => symbol)).map((symbol) => ({ id: symbol, label: symbol }));
    const [activePriceTab, setActivePriceTab] = (0, react_1.useState)("");
    React.useEffect(() => {
        var _a;
        setActivePriceTab((_a = tokenOptions[0]) === null || _a === void 0 ? void 0 : _a.id);
    }, [(_a = tokenOptions[0]) === null || _a === void 0 ? void 0 : _a.id]);
    const { data: tokenContainer, forceReload } = (0, token_hooks_1.useTokenById)(tokenId);
    const { waitBlock } = (0, block_tracker_hooks_1.useBlockTracker)(updateToken);
    (0, react_1.useEffect)(() => {
        return addListener(_dictionaries_1.EVENTS.WAIT_BLOCK_EVENT, (blockId) => waitBlock(blockId));
    }, []);
    if (!tokenContainer) {
        return null;
    }
    const { token: { name, attributes }, } = tokenContainer;
    return (React.createElement("div", { className: "p-token-page" },
        React.createElement("div", { className: "p-token-page__preview" },
            React.createElement(token_preview_1.TokenPreview, { nft: tokenContainer.token })),
        React.createElement(token_details_1.TokenDetails, { tokenContainer: tokenContainer, forceReload: forceReload }),
        attributes.length > 0 && (React.createElement("div", { className: "p-token-page__section" },
            React.createElement(title_1.Title, { isHighlighted: true, title: translator("propertyOfLabel", { name }) }),
            React.createElement("div", { className: "p-token-page__properties" }, attributes.map(({ value, name }, i) => {
                return (React.createElement("div", { className: "p-token-page__property", key: name + value + i },
                    React.createElement("div", { className: "p-token-page__property-name" }, name),
                    React.createElement("div", { className: "p-token-page__property-value" }, value)));
            })))),
        React.createElement("div", { className: "p-token-page__section" },
            React.createElement(title_1.Title, { isHighlighted: true, title: translator("tokenActivityHistoryTitle") }),
            React.createElement(token_history_1.TokenHistory, { nft: tokenContainer.token })),
        React.createElement("div", { className: "p-token-page__section" },
            React.createElement(title_1.Title, { isHighlighted: true, title: translator("priceHistoryTitle") }),
            React.createElement("div", { className: "p-token-page__section-tab" },
                React.createElement(tabs_1.Tabs, { tabs: tokenOptions, selectedTabId: activePriceTab, selectTab: setActivePriceTab })),
            React.createElement(price_history_1.PriceHistory, { nft: tokenContainer.token, symbol: activePriceTab }))));
};
exports.default = TokenPage;
function updateToken() {
    throw new Error("Function not implemented.");
}
