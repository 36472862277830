"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createValidator = exports.DEFAULT_FROM = void 0;
const validators_1 = require("@hooks/forms/validators");
exports.DEFAULT_FROM = {
    fixedPrice: "0",
    paymentToken: undefined,
};
const createValidator = (translator) => ({
    paymentToken: [(0, validators_1.createRequiredValidator)(translator("validation.required"))],
    fixedPrice: [
        (0, validators_1.createRequiredValidator)(translator("validation.required")),
        (0, validators_1.createNonZeroNumberValidator)(translator("validation.notZero")),
    ],
});
exports.createValidator = createValidator;
