"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withInitialData = void 0;
const react_1 = __importStar(require("react"));
const hooks_1 = require("@app/store/hooks");
const core_1 = require("@web3-react/core");
const identity_reducer_1 = require("@app/store/reducers/identity.reducer");
const cart_reducer_1 = require("@app/store/reducers/cart.reducer");
const _abi_1 = require("@abi");
const loader_1 = require("@shared-modules/components/loader");
const popup_1 = require("@shared-modules/components/popup");
const network_switcher_1 = require("@components/network-switcher");
const logo_1 = require("@shared-modules/components/logo");
const data_loader_hooks_1 = require("@hooks/data-loader.hooks");
const user_service_1 = require("@app/services/user.service");
const warehouse_service_1 = require("@app/services/warehouse.service");
const tokens_reducer_1 = require("@app/store/reducers/tokens.reducer");
const application_state_reducer_1 = require("@app/store/reducers/application-state.reducer");
const cart_service_1 = require("@app/services/cart.service");
const userService = new user_service_1.UserService();
const warehouseService = warehouse_service_1.WarehouseService.getInstance();
const useLoadInitialData = (dispatch, account, language) => {
    const { isCompleted: isContractLoadingCompleted } = (0, data_loader_hooks_1.useDataLoader)(() => _abi_1.ContractManager.loadContractsConfig(), [], undefined);
    const { isCompleted: isTokenListLoadingCompleted } = (0, data_loader_hooks_1.useDataLoader)(() => __awaiter(void 0, void 0, void 0, function* () {
        const tokenList = yield warehouseService.getTokenList();
        dispatch((0, tokens_reducer_1.setTokeList)(tokenList));
    }), [], undefined);
    const { isCompleted: isTranslationLoadingCompleted } = (0, data_loader_hooks_1.useDataLoader)(() => __awaiter(void 0, void 0, void 0, function* () {
        const translations = yield warehouseService.getTranslations(language);
        dispatch((0, application_state_reducer_1.setTranslations)(translations));
    }), [language], undefined);
    const { isCompleted: isProfileLoadingCompleted } = (0, data_loader_hooks_1.useDataLoader)(() => __awaiter(void 0, void 0, void 0, function* () {
        if (account) {
            const profile = yield userService.getUserByAccount(account);
            dispatch((0, identity_reducer_1.updateUser)(profile));
        }
    }), [account], undefined);
    (0, react_1.useEffect)(() => {
        dispatch((0, cart_reducer_1.setCart)(new cart_service_1.CartService().getCurrentCart()));
    }, []);
    return (isProfileLoadingCompleted &&
        isContractLoadingCompleted &&
        isTokenListLoadingCompleted &&
        isTranslationLoadingCompleted);
};
const withInitialData = (WrappedComponent) => {
    return (props) => {
        const dispatch = (0, hooks_1.useAppDispatch)();
        const selectedLanguage = (0, application_state_reducer_1.useSelectedLanguage)();
        const { account, chainId } = (0, core_1.useWeb3React)();
        const isLoaded = useLoadInitialData(dispatch, account || "", selectedLanguage);
        const [isForceClosed, setIsForceClosed] = (0, react_1.useState)(false);
        const isSupportableChainId = _abi_1.ContractManager.getSupportedChains().some((supportableChainId) => supportableChainId === chainId);
        return (react_1.default.createElement(react_1.default.Fragment, null,
            isLoaded ? (react_1.default.createElement(WrappedComponent, Object.assign({}, props))) : (react_1.default.createElement("div", { className: "g-loader" },
                react_1.default.createElement("div", { className: "g-loader__logo" },
                    react_1.default.createElement(logo_1.Logo, { scale: 2 })),
                react_1.default.createElement(loader_1.Loader, { scale: 2 }))),
            isLoaded && !isSupportableChainId && chainId && !isForceClosed && (react_1.default.createElement(popup_1.Popup, { isOpened: true, onClose: () => setIsForceClosed(true) },
                react_1.default.createElement(network_switcher_1.NetworkSwitcher, { supportedChainIds: _abi_1.ContractManager.getSupportedChains() })))));
    };
};
exports.withInitialData = withInitialData;
