"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultLanguage = void 0;
const object_1 = require("./object");
const getDefaultLanguage = (supportedLanguages, defaultLanguage) => {
    const languages = navigator.languages;
    return supportedLanguages.reduce(({ currentLanguage, languagePriority }, language) => {
        const priority = languages.findIndex((languageInPriority) => (0, object_1.caseInsensitiveCompare)(languageInPriority, language));
        if (priority < languagePriority && priority >= 0) {
            return { currentLanguage: language, languagePriority: priority };
        }
        return { currentLanguage, languagePriority };
    }, { currentLanguage: defaultLanguage, languagePriority: 99999 }).currentLanguage;
};
exports.getDefaultLanguage = getDefaultLanguage;
