"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyTicketForm = void 0;
const approve_hooks_1 = require("@hooks/contracts/approve.hooks");
const lottery_hooks_1 = require("@hooks/contracts/lottery.hooks");
const form_hooks_1 = require("@hooks/form.hooks");
const routing_hooks_1 = require("@hooks/routing.hooks");
const button_1 = require("@shared-modules/components/button");
const text_input_1 = require("@shared-modules/components/text-input");
const http_1 = require("@utils/http");
const object_1 = require("@utils/object");
const number_1 = require("@utils/object/number");
const core_1 = require("@web3-react/core");
const React = __importStar(require("react"));
const event_timer_1 = require("../event-timer");
const token_info_1 = require("../token-info");
const helper_1 = require("./helper");
const translation_hooks_1 = require("@hooks/translation.hooks");
require("./styles.scss");
const currency_label_1 = require("@components/currency-label");
const BuyTicketForm = (props) => {
    const { lottery, updateToken } = props;
    const translator = (0, translation_hooks_1.usePagesTranslation)("tokenPage.buyTicketForm");
    const commonTranslator = (0, translation_hooks_1.useCommonTranslation)();
    const { account } = (0, core_1.useWeb3React)();
    const paymentTokenBalance = (0, approve_hooks_1.useTokenBalance)(lottery.paymentTokenAddress);
    const myTicketsAmount = lottery.tickets.filter(({ account: buyer }) => (0, object_1.caseInsensitiveCompare)(buyer, account)).length;
    const [approvalState, approveCallback] = (0, approve_hooks_1.useApprove)(lottery.paymentTokenAddress, lottery === null || lottery === void 0 ? void 0 : lottery.contractAddress);
    const [isBuyingProcess, setBuyingProcess] = React.useState(false);
    const isInProgress = approvalState === approve_hooks_1.ApprovalState.Unknown || approvalState === approve_hooks_1.ApprovalState.Pending;
    const isApproved = approvalState === approve_hooks_1.ApprovalState.Approved;
    const buyTickets = (0, lottery_hooks_1.useBuyTickets)(lottery.tokenId, lottery === null || lottery === void 0 ? void 0 : lottery.contractAddress);
    const redirectToLottery = (0, routing_hooks_1.useRedirectTo)(`/explore/lotteries${(0, http_1.parseToUrlQuery)({ section: "myTickets" })}`, true);
    const ticketsLeft = lottery.totalTickets - lottery.soldTickets;
    const isSoldOut = ticketsLeft === 0;
    const { form, fieldHandlers, submit, validationResults } = (0, form_hooks_1.useForm)({
        initialData: helper_1.DEFAULT_FORM,
        validators: (0, helper_1.createValidators)((0, number_1.normalizeValue)(paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.balance, paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.decimals).toNumber(), (0, number_1.normalizeValue)(lottery.ticketPrice, paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.decimals).toNumber(), commonTranslator),
        submit: (form, validationResults) => __awaiter(void 0, void 0, void 0, function* () {
            if (validationResults.isValid) {
                setBuyingProcess(true);
                try {
                    yield buyTickets(+form.tickets);
                    redirectToLottery();
                }
                catch (error) { }
                updateToken();
                setBuyingProcess(false);
            }
        }),
    });
    const totalPrice = (0, number_1.normalizeValue)(lottery === null || lottery === void 0 ? void 0 : lottery.ticketPrice, paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.decimals).times(form.tickets);
    const isLotteryExpired = lottery.endLotteryDate < new Date().getTime();
    const lotteryInfo = [
        {
            label: translator("ticketPriceLabel"),
            currencySymbol: paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.symbol,
            value: (0, number_1.formatAndNormalizeToken)(paymentTokenBalance, lottery === null || lottery === void 0 ? void 0 : lottery.ticketPrice, paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.decimals),
        },
        {
            label: translator("iBoughtLabel"),
            value: translator("myTicketsValue", { tickets: myTicketsAmount }),
            comment: translator("myShare", {
                share: lottery ? (0, number_1.formatBigNumber)(((myTicketsAmount || 0) / lottery.totalTickets) * 100) : "-",
            }),
        },
    ];
    return (React.createElement("div", { className: "c-token-page-buy-tickets-form" },
        React.createElement("div", { className: "c-token-page-buy-tickets-form__tickets" },
            React.createElement("div", { className: "c-token-page-buy-tickets-form__tickets-label" }, translator("ticketsSoldLabel")),
            React.createElement("div", { className: "c-token-page-buy-tickets-form__tickets-value" }, isSoldOut ? translator("soldOutLabel") : `${lottery.soldTickets}/${lottery.totalTickets}`)),
        React.createElement(event_timer_1.EventTimer, { startTime: 0, endTime: lottery.endLotteryDate, onUpdate: updateToken, eventName: translator("eventName") }),
        React.createElement(token_info_1.TokenInfo, { cells: lotteryInfo }),
        !isLotteryExpired && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "c-token-page-buy-tickets-form__input" },
                React.createElement(text_input_1.TextInput, { label: translator("ticketsLabel"), placeholder: translator("ticketsPlaceholder"), value: form.tickets, handleChange: fieldHandlers.tickets, error: validationResults.results.tickets, type: "positiveInteger" })),
            React.createElement("div", { className: "c-token-page-buy-tickets-form__buy" },
                React.createElement("div", { className: "c-token-page-buy-tickets-form__buy-info" },
                    React.createElement("div", { className: "c-token-page-buy-tickets-form__buy-label" }, translator("userSpendLabel")),
                    React.createElement("div", { className: "c-token-page-buy-tickets-form__buy-value" }, paymentTokenBalance && (React.createElement(currency_label_1.CurrencyLabel, { tokenAddress: paymentTokenBalance.address, amount: totalPrice, isNormalized: true })))),
                React.createElement(button_1.Button, { loading: isInProgress, type: "thirtery", size: "small", disabled: isApproved || isInProgress || isSoldOut, onClick: approveCallback }, commonTranslator("approveButton")),
                React.createElement(button_1.Button, { loading: isBuyingProcess, disabled: !isApproved || isBuyingProcess || isSoldOut, size: "small", onClick: submit }, translator(isSoldOut ? "soldOutButton" : "buyButton"))))),
        isLotteryExpired && React.createElement("b", { className: "c-token-page-buy-tickets-form__warning" }, translator("lotteryEndedText"))));
};
exports.BuyTicketForm = BuyTicketForm;
