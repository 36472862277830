"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseCard = void 0;
const React = __importStar(require("react"));
const card_video_player_1 = require("../card-video-player");
const card_audio_player_1 = require("../card-audio-player");
require("./styles.scss");
const react_1 = require("react");
const icons_1 = require("@shared-modules/components/icons");
const BaseCard = (props) => {
    const { metadata, children, onPreviewClick, tokenPageUrl, baseMetaDataUrl } = props;
    const [isActive, setIsActive] = (0, react_1.useState)(false);
    const onClick = (event) => {
        event.preventDefault();
        onPreviewClick();
    };
    if (!metadata) {
        return null;
    }
    const previewUrl = baseMetaDataUrl + metadata.preview;
    let viewContent = null;
    if (metadata.type === "image" || (metadata.type === "3d" && !metadata.video)) {
        viewContent = React.createElement("img", { className: "c-base-card__preview", src: previewUrl });
    }
    else if (metadata.type === "video" || (metadata.type === "3d" && metadata.video)) {
        const videoUrl = baseMetaDataUrl + metadata.video;
        viewContent = (React.createElement(card_video_player_1.CardVideoPlayer, { contentClassName: "c-base-card__preview", src: videoUrl, preview: previewUrl, onPlay: () => setIsActive(true), onPause: () => setIsActive(false) }));
    }
    else if (metadata.type === "audio") {
        const audioUrl = baseMetaDataUrl + metadata.audio;
        viewContent = React.createElement(card_audio_player_1.CardAudioPlayer, { contentClassName: "c-base-card__preview", src: audioUrl, preview: previewUrl });
    }
    return (React.createElement("div", { className: "c-base-card" },
        React.createElement("a", { href: tokenPageUrl, onClick: onClick, className: "c-base-card__preview-container" },
            React.createElement("div", { className: "c-base-card__icon" },
                metadata.type === "3d" && React.createElement(icons_1.ArtIcon, { scale: 1, color: "red" }),
                metadata.type === "audio" && React.createElement(icons_1.AudioIcon, { scale: 1, color: "red" }),
                metadata.type === "image" && React.createElement(icons_1.ImageIcon, { scale: 1, color: "red" }),
                metadata.type === "video" && React.createElement(icons_1.VideoIcon, { scale: 1, color: "red" })),
            viewContent),
        React.createElement("div", { className: "c-base-card__content-section", style: { opacity: isActive ? 0 : 1 } },
            React.createElement("div", { className: "c-base-card__content" }, children))));
};
exports.BaseCard = BaseCard;
