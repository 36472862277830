"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInClaim = exports.useInRoleLazy = exports.useInRole = exports.useCurrentUser = exports.forgetUser = exports.updateUser = exports.identitySlice = exports.UserClaim = exports.AppRole = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const object_1 = require("@utils/object");
const core_1 = require("@web3-react/core");
const hooks_1 = require("../hooks");
var AppRole;
(function (AppRole) {
    AppRole[AppRole["admin"] = 0] = "admin";
    AppRole[AppRole["minter"] = 1] = "minter";
    AppRole[AppRole["deny"] = 2] = "deny";
})(AppRole = exports.AppRole || (exports.AppRole = {}));
var UserClaim;
(function (UserClaim) {
    UserClaim[UserClaim["role"] = 0] = "role";
    UserClaim[UserClaim["account"] = 1] = "account";
    UserClaim[UserClaim["profile"] = 2] = "profile";
    UserClaim[UserClaim["isIdentified"] = 3] = "isIdentified";
})(UserClaim = exports.UserClaim || (exports.UserClaim = {}));
const initialState = {
    currentUser: undefined,
    roles: [],
};
exports.identitySlice = (0, toolkit_1.createSlice)({
    name: "identity",
    initialState,
    reducers: {
        updateUser: (state, action) => {
            state.currentUser = action.payload;
            state.roles = [];
            action.payload.minterStatus === "minter" && state.roles.push(AppRole.minter);
            action.payload.isAdmin && state.roles.push(AppRole.admin);
        },
        forgetUser: (state) => {
            state.currentUser = undefined;
            state.roles = [];
        },
    },
});
_a = exports.identitySlice.actions, exports.updateUser = _a.updateUser, exports.forgetUser = _a.forgetUser;
const useCurrentUser = () => (0, hooks_1.useAppSelector)(({ identity }) => identity.currentUser);
exports.useCurrentUser = useCurrentUser;
const useInRole = (role) => (0, hooks_1.useAppSelector)(({ identity: { roles = [] } }) => roles.includes(role));
exports.useInRole = useInRole;
const useInRoleLazy = () => (0, hooks_1.useAppSelector)(({ identity: { roles = [] } }) => (role, defaultValue = false) => role ? roles.includes(role) : defaultValue);
exports.useInRoleLazy = useInRoleLazy;
const useInClaim = (claim) => {
    const { account } = (0, core_1.useWeb3React)();
    const requestedClaim = claim["claimType"] ? claim.claimType : claim;
    const requestedClaimValue = claim["claimType"] ? claim.value : undefined;
    return (0, hooks_1.useAppSelector)(({ identity: { roles = [], currentUser } }) => {
        switch (requestedClaim) {
            case UserClaim.role:
                return roles.includes(requestedClaimValue);
            case UserClaim.account:
                return (0, object_1.caseInsensitiveCompare)(account, requestedClaimValue);
            case UserClaim.profile:
                return Boolean(currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName) && Boolean(currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName);
            case UserClaim.isIdentified:
                return Boolean(account);
            default:
                return false;
        }
    });
};
exports.useInClaim = useInClaim;
exports.default = exports.identitySlice.reducer;
