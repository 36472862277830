"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createValidator = exports.DEFAULT_FORM = void 0;
const validators_1 = require("@hooks/forms/validators");
const object_1 = require("@utils/object");
exports.DEFAULT_FORM = {
    endDate: undefined,
    paymentToken: undefined,
    maxTicket: "",
    ticketPrice: "",
};
const createValidator = (translator) => ({
    paymentToken: [(0, validators_1.createRequiredValidator)(translator("validation.required"))],
    ticketPrice: [
        (0, validators_1.createRequiredValidator)(translator("validation.required")),
        (0, validators_1.createNonZeroNumberValidator)(translator("validation.notZero")),
    ],
    maxTicket: [
        (0, validators_1.createRequiredValidator)(translator("validation.required")),
        (0, validators_1.createNonZeroNumberValidator)(translator("validation.notZero")),
    ],
    endDate: [
        (0, validators_1.createRequiredValidator)(translator("validation.required")),
        (0, validators_1.createAfterThanValidator)(new Date(), translator("validation.afterDate", { boundary: (0, object_1.formatHumanDateTime)(new Date()) })),
    ],
});
exports.createValidator = createValidator;
