"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionInstantForm = void 0;
const approve_hooks_1 = require("@hooks/contracts/approve.hooks");
const button_1 = require("@shared-modules/components/button");
const React = __importStar(require("react"));
const translation_hooks_1 = require("@hooks/translation.hooks");
require("./styles.scss");
const auction_hooks_1 = require("@hooks/contracts/auction.hooks");
const block_tracker_hooks_1 = require("@hooks/block-tracker.hooks");
const react_1 = require("react");
const currency_label_1 = require("@components/currency-label");
const AuctionInstantForm = (props) => {
    const { auction, updateToken, allowToBuy } = props;
    const translator = (0, translation_hooks_1.usePagesTranslation)("tokenPage.auctionInstantForm");
    const commonTranslator = (0, translation_hooks_1.useCommonTranslation)();
    const [isBuyingProcess, setIsBuyingProcess] = (0, react_1.useState)(false);
    const paymentTokenBalance = (0, approve_hooks_1.useTokenBalance)(auction.paymentTokenAddress);
    const { waitBlock } = (0, block_tracker_hooks_1.useBlockTracker)(() => {
        updateToken();
        setIsBuyingProcess(false);
    });
    const [approvalState, approveCallback] = (0, approve_hooks_1.useApprove)(auction.paymentTokenAddress, auction.contractAddress);
    const buyNow = (0, auction_hooks_1.useBuyNow)(auction.tokenId, auction.contractAddress);
    const isApproveInProgress = approvalState === approve_hooks_1.ApprovalState.Unknown || approvalState === approve_hooks_1.ApprovalState.Pending;
    const isApproved = approvalState === approve_hooks_1.ApprovalState.Approved;
    const buyNowHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsBuyingProcess(true);
        try {
            const { blockNumber } = (yield buyNow()) || {};
            waitBlock(blockNumber);
        }
        catch (error) {
            console.error(error);
            setIsBuyingProcess(false);
        }
    });
    const isBalanceNotEnough = paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.balance.isLessThan(auction.buyNowPrice);
    return (React.createElement("div", { className: "c-token-page-auction-instant-form" },
        React.createElement("div", { className: "c-token-page-auction-instant-form__buy-info" },
            React.createElement("div", { className: "c-token-page-auction-instant-form__buy-label" }, translator("instantBuyLabel")),
            React.createElement("div", { className: "c-token-page-auction-instant-form__buy-value" }, paymentTokenBalance && (React.createElement(currency_label_1.CurrencyLabel, { tokenAddress: paymentTokenBalance.address, amount: auction.buyNowPrice }))),
            isBalanceNotEnough && (React.createElement("div", { className: "c-token-page-auction-instant-form__validation" }, commonTranslator("validation.balanceNotEnough")))),
        React.createElement(button_1.Button, { loading: isApproveInProgress, size: "small", type: "thirtery", disabled: isApproved || isApproveInProgress || !allowToBuy, onClick: approveCallback }, commonTranslator("approveButton")),
        React.createElement(button_1.Button, { size: "small", onClick: buyNowHandler, loading: isBuyingProcess, disabled: !isApproved || !allowToBuy }, translator("buyNowButton"))));
};
exports.AuctionInstantForm = AuctionInstantForm;
