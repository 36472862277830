"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionCard = void 0;
const user_mini_profile_1 = require("@components/user-mini-profile");
const translation_hooks_1 = require("@hooks/translation.hooks");
const button_1 = require("@shared-modules/components/button");
const object_1 = require("@utils/object");
const React = __importStar(require("react"));
require("./styles.scss");
const CollectionCard = (props) => {
    const { collection } = props;
    const translator = (0, translation_hooks_1.useComponentTranslation)("collectionCard");
    const tokens = collection.nfts.slice(0, 3).map(({ metadata, metaUrlBase }) => metaUrlBase + (metadata === null || metadata === void 0 ? void 0 : metadata.preview));
    let collectionAmountLabel = "0";
    if (collection.tokensAmount > 101) {
        collectionAmountLabel = "+99";
    }
    else if (collection.tokensAmount > 2) {
        collectionAmountLabel = (collection.tokensAmount - 2).toString();
    }
    return (React.createElement("div", { className: "c-collection-card", style: { backgroundImage: `url(${collection.coverUrl})` } },
        React.createElement("div", { className: "c-collection-card__content" },
            React.createElement("div", { className: "c-collection-card__collection-avatar", style: { backgroundImage: `url(${collection.previewUrl})` } }),
            React.createElement(user_mini_profile_1.UserMiniProfile, { avatar: collection.creator.avatar, address: collection.creator.wallet, title: (0, object_1.concatStringsWithWhitespace)(collection.creator.firstName, collection.creator.lastName) }),
            React.createElement("div", { className: "c-collection-card__title" }, collection.title),
            React.createElement("div", { className: "c-collection-card__info" },
                React.createElement("div", { className: "c-collection-card__info-grid" },
                    React.createElement("div", { className: "c-collection-card__info-grid-label" }, translator("itemsInCollectionLabel")),
                    React.createElement("div", { className: "c-collection-card__info-grid-value" }, collection.tokensAmount)),
                React.createElement(button_1.Button, { tag: "a", buttonUrl: `/collections/${collection.id}`, size: "small" }, translator("openButton"))),
            React.createElement("div", { className: "c-collection-card__preview" },
                [...tokens, "", ""].slice(0, 2).map((nftsPreview) => (React.createElement("div", { key: nftsPreview, className: "c-collection-card__preview-card", style: { backgroundImage: `url(${nftsPreview})` } }))),
                collectionAmountLabel !== "0" && (React.createElement("div", { className: "c-collection-card__preview-card c-collection-card__preview-card--last", style: {
                        backgroundImage: collectionAmountLabel !== "1"
                            ? `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0, 0.7)), url(${tokens[2]})`
                            : "",
                    } }, collectionAmountLabel !== "1" && collectionAmountLabel))))));
};
exports.CollectionCard = CollectionCard;
