"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileEditForm = void 0;
const react_1 = __importStar(require("react"));
const form_hooks_1 = require("@hooks/form.hooks");
const button_1 = require("@shared-modules/components/button");
const text_input_1 = require("@shared-modules/components/text-input");
const title_1 = require("@shared-modules/components/title");
const helper_1 = require("../../helper");
const react_router_dom_1 = require("react-router-dom");
const translation_hooks_1 = require("@hooks/translation.hooks");
require("./styles.scss");
const textarea_input_1 = require("@shared-modules/components/textarea-input");
const ProfileEditForm = (props) => {
    const { updateForm, profile } = props;
    const translator = (0, translation_hooks_1.usePagesTranslation)("profileEditPage.profileEditForm");
    const { push } = (0, react_router_dom_1.useHistory)();
    const redirectToProfile = () => push("/profile");
    const submitForm = (form) => updateForm(form);
    const { form, fieldHandlers, submit, resetForm } = (0, form_hooks_1.useForm)({
        initialData: helper_1.DEFAULT_FORM,
        submit: submitForm,
    });
    (0, react_1.useEffect)(() => {
        profile && resetForm(Object.assign(Object.assign({}, helper_1.DEFAULT_FORM), profile));
    }, [profile]);
    return (react_1.default.createElement("div", { className: "c-profile-edit-form-container" },
        react_1.default.createElement(title_1.Title, { title: translator("commonSectionTitle"), tag: "h3" }),
        react_1.default.createElement("div", { className: "c-profile-edit-form-container__form-field" },
            react_1.default.createElement(text_input_1.TextInput, { value: form.firstName, label: translator("firstNameLabel"), placeholder: translator("firstNamePlaceholder"), handleChange: fieldHandlers.firstName })),
        react_1.default.createElement("div", { className: "c-profile-edit-form-container__form-field" },
            react_1.default.createElement(text_input_1.TextInput, { value: form.lastName, label: translator("lastNameLabel"), placeholder: translator("lastNamePlaceholder"), handleChange: fieldHandlers.lastName })),
        react_1.default.createElement("div", { className: "c-profile-edit-form-container__form-field" },
            react_1.default.createElement(textarea_input_1.TextareaInput, { value: form.description, label: translator("descriptionLabel"), placeholder: translator("descriptionPlaceholder"), handleChange: fieldHandlers.description })),
        react_1.default.createElement(title_1.Title, { title: translator("socialSectionTitle"), tag: "h3" }),
        react_1.default.createElement("div", { className: "c-profile-edit-form-container__form-field" },
            react_1.default.createElement(text_input_1.TextInput, { value: form.email, label: translator("emailLabel"), placeholder: translator("emailPlaceholder"), handleChange: fieldHandlers.email })),
        react_1.default.createElement("div", { className: "c-profile-edit-form-container__form-field" },
            react_1.default.createElement(text_input_1.TextInput, { value: form.instagram, label: translator("instagramLabel"), placeholder: translator("instagramPlaceholder"), handleChange: fieldHandlers.instagram })),
        react_1.default.createElement("div", { className: "c-profile-edit-form-container__form-field" },
            react_1.default.createElement(text_input_1.TextInput, { value: form.telegram, label: translator("telegramLabel"), placeholder: translator("telegramPlaceholder"), handleChange: fieldHandlers.telegram })),
        react_1.default.createElement("div", { className: "c-profile-edit-form-container__form-field" },
            react_1.default.createElement(text_input_1.TextInput, { value: form.twitter, label: translator("twitterLabel"), placeholder: translator("twitterPlaceholder"), handleChange: fieldHandlers.twitter })),
        react_1.default.createElement("p", { className: "c-profile-edit-form-container__sign-text" }, translator("signAlert")),
        react_1.default.createElement("div", { className: "c-profile-edit-form-container__buttons-container" },
            react_1.default.createElement(button_1.Button, { type: "secondary", onClick: redirectToProfile }, translator("cancelButton")),
            react_1.default.createElement(button_1.Button, { type: "primary", onClick: submit }, translator("updateButton")))));
};
exports.ProfileEditForm = ProfileEditForm;
