"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventType = void 0;
var EventType;
(function (EventType) {
    EventType["mint"] = "mint";
    EventType["sell"] = "sell";
    EventType["auctionBid"] = "auctionBid";
    EventType["auctionBuyNow"] = "auctionBuyNow";
    EventType["lottery"] = "lottery";
})(EventType = exports.EventType || (exports.EventType = {}));
