"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecipeForm = void 0;
const withListener_1 = require("@app/hoc/withListener");
const cart_reducer_1 = require("@app/store/reducers/cart.reducer");
const currency_label_1 = require("@components/currency-label");
const _dictionaries_1 = require("@dictionaries");
const approve_hooks_1 = require("@hooks/contracts/approve.hooks");
const market_hooks_1 = require("@hooks/contracts/market.hooks");
const routing_hooks_1 = require("@hooks/routing.hooks");
const translation_hooks_1 = require("@hooks/translation.hooks");
const button_1 = require("@shared-modules/components/button");
const react_1 = __importStar(require("react"));
require("./styles.scss");
const RecipeForm = (props) => {
    const { paymentToken, nftIds, sum, marketAddress } = props;
    const [isBuying, setIsBuying] = (0, react_1.useState)(false);
    const cleanCart = (0, cart_reducer_1.useCartCleaner)();
    const cartToggler = (0, cart_reducer_1.useCartToggler)();
    const { notifyListener } = (0, withListener_1.useListener)();
    const [approvalState, approveCallback] = (0, approve_hooks_1.useApprove)(paymentToken.address, marketAddress, sum);
    const isApproveInProgress = approvalState === approve_hooks_1.ApprovalState.Unknown || approvalState === approve_hooks_1.ApprovalState.Pending;
    const isApproved = approvalState === approve_hooks_1.ApprovalState.Approved;
    const translator = (0, translation_hooks_1.useComponentTranslation)("cartPopup.recipeForm");
    const bulkBuy = (0, market_hooks_1.useBulkBuyNFT)(marketAddress);
    const redirectToProfile = (0, routing_hooks_1.useRedirectTo)("/profile");
    const buyNfts = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsBuying(true);
            const { blockNumber } = (yield bulkBuy(nftIds)) || {};
            const tokenIdsLeft = cleanCart(nftIds);
            if (tokenIdsLeft.length === 0) {
                notifyListener(_dictionaries_1.EVENTS.WAIT_BLOCK_EVENT, blockNumber);
                cartToggler();
                redirectToProfile();
            }
        }
        catch (error) {
            console.error(error);
        }
        setIsBuying(false);
    });
    return (react_1.default.createElement("div", { className: "c-cart-popup-recipe-form" },
        react_1.default.createElement("div", { className: "c-cart-popup-recipe-form__title" },
            translator("totalInCurrencyLabel", { symbol: paymentToken === null || paymentToken === void 0 ? void 0 : paymentToken.symbol }),
            react_1.default.createElement("span", null, translator("numberOfNftsLabel", { amount: nftIds.length }))),
        react_1.default.createElement("div", { className: "c-cart-popup-recipe-form__price" },
            react_1.default.createElement(currency_label_1.CurrencyLabel, { amount: sum, tokenAddress: (paymentToken === null || paymentToken === void 0 ? void 0 : paymentToken.address) || "" })),
        react_1.default.createElement(button_1.Button, { disabled: isApproved, onClick: approveCallback, loading: isApproveInProgress, size: "small", type: "secondary" }, translator("approveButton")),
        react_1.default.createElement(button_1.Button, { disabled: !isApproved, onClick: buyNfts, loading: isBuying, size: "small" }, translator("buyButton"))));
};
exports.RecipeForm = RecipeForm;
