"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidCastError = void 0;
const base_error_1 = require("./base-error");
class InvalidCastError extends base_error_1.BaseError {
    constructor(message, context, parentError) {
        super(message, context, parentError);
        this.name = "InvalidCastError";
    }
}
exports.InvalidCastError = InvalidCastError;
