"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const core_1 = require("@web3-react/core");
const button_1 = require("@shared-modules/components/button");
const hooks_1 = require("@app/store/hooks");
const popup_1 = require("@shared-modules/components/popup");
const profile_1 = require("./components/profile");
const wallet_form_1 = require("./components/wallet-form");
const identity_reducer_1 = require("@app/store/reducers/identity.reducer");
const helper_1 = require("./helper");
const routing_hooks_1 = require("@hooks/routing.hooks");
require("./styles.scss");
const burger_1 = require("@shared-modules/components/burger");
const application_state_reducer_1 = require("@app/store/reducers/application-state.reducer");
const translation_hooks_1 = require("@hooks/translation.hooks");
const language_selector_1 = require("./components/language-selector");
const icons_1 = require("@shared-modules/components/icons");
const cart_reducer_1 = require("@app/store/reducers/cart.reducer");
const Header = () => {
    const dispatcher = (0, hooks_1.useAppDispatch)();
    const walletPopupToggle = (0, application_state_reducer_1.useWalletPopupStateToggle)();
    const isOpenedWalletPopup = (0, application_state_reducer_1.useWalletPopupState)();
    const translator = (0, translation_hooks_1.useComponentTranslation)("navigation");
    const context = (0, core_1.useWeb3React)();
    (0, helper_1.useInitialConnect)(context);
    const { library, deactivate, activate, connector, account } = context;
    const toggleMenu = (0, application_state_reducer_1.useLeftHandNavToggle)();
    const isNavActive = (0, application_state_reducer_1.useLeftHandNavState)();
    const redirectToMain = React.useCallback((0, routing_hooks_1.useRedirectTo)("/"), []);
    const activateConnector = React.useCallback((connector) => __awaiter(void 0, void 0, void 0, function* () {
        yield activate(connector);
        walletPopupToggle(false);
    }), [library, activate, account]);
    const logout = React.useCallback(() => {
        deactivate();
        dispatcher((0, identity_reducer_1.forgetUser)());
        if (connector && "close" in (connector || {})) {
            connector.close();
        }
    }, [deactivate, connector]);
    const isMinter = (0, identity_reducer_1.useInRole)(identity_reducer_1.AppRole.minter);
    const isIdentifiedUser = (0, identity_reducer_1.useInClaim)(identity_reducer_1.UserClaim.isIdentified);
    const cart = (0, cart_reducer_1.useCart)();
    const togglePopupVisibility = (0, cart_reducer_1.useCartToggler)();
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: "c-header" },
            React.createElement("div", { className: "c-header__burger" },
                React.createElement(burger_1.Burger, { currentState: isNavActive, toggle: toggleMenu })),
            React.createElement("div", { onClick: redirectToMain, className: "c-header__logo" }),
            React.createElement("nav", { className: "c-header__nav" },
                React.createElement(react_router_dom_1.Link, { className: "c-header__nav-item g-link-alternative", to: "/explore/lotteries" }, translator("lotteryLinkLabel")),
                !isMinter && (React.createElement(react_router_dom_1.Link, { className: "c-header__nav-item g-link-alternative", to: "/profile/become-creator" }, translator("becomeCreatorLinkLabel"))),
                React.createElement(react_router_dom_1.Link, { className: "c-header__nav-item g-link-alternative", to: "/rating" }, translator("ratingLinkLabel")),
                React.createElement(react_router_dom_1.Link, { className: "c-header__nav-item g-link", to: "/explore" }, translator("explorerLinkLabel"))),
            React.createElement("div", { className: "c-header__language-selector" },
                React.createElement(language_selector_1.LanguageSelector, null)),
            React.createElement("div", { className: "c-header__cart not-mobile" },
                React.createElement("span", { className: "c-header__cart-container not-mobile", onClick: togglePopupVisibility },
                    React.createElement(icons_1.CartIcon, { color: "#272727" }),
                    cart.length > 0 && (React.createElement("span", { className: "c-header__cart-amount not-mobile" }, cart.length > 9 ? "+9" : cart.length)))),
            React.createElement("div", { className: "c-header__wallet not-mobile" }, isIdentifiedUser ? (React.createElement(profile_1.Profile, { disconnect: logout })) : (React.createElement(button_1.Button, { size: "small", onClick: () => walletPopupToggle(true) }, translator("connectButton")))),
            React.createElement("div", { className: "c-header__wallet mobile" }, isIdentifiedUser ? (React.createElement(profile_1.Profile, { disconnect: logout })) : (React.createElement("div", { onClick: () => walletPopupToggle(true), className: "c-header__wallet-mobile" },
                React.createElement(icons_1.WalletIcon, { color: "white" }))))),
        React.createElement(popup_1.Popup, { isOpened: isOpenedWalletPopup, onClose: () => walletPopupToggle(false) },
            React.createElement(wallet_form_1.WalletForm, { activateConnector: activateConnector }))));
};
exports.Header = Header;
