"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStorage = void 0;
class LocalStorage {
    static getValue(name) {
        const jsonString = localStorage.getItem(name);
        if (jsonString) {
            return JSON.parse(jsonString);
        }
        return undefined;
    }
    static updateValue(name, object) {
        const jsonString = localStorage.getItem(name);
        if (jsonString) {
            const newObject = Object.assign(Object.assign({}, JSON.parse(jsonString)), object);
            localStorage.setItem(name, JSON.stringify(newObject));
        }
        else {
            localStorage.setItem(name, JSON.stringify(object));
        }
    }
    static remove(name) {
        localStorage.removeItem(name);
    }
}
exports.LocalStorage = LocalStorage;
