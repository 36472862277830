"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const title_1 = require("@shared-modules/components/title");
const user_hooks_1 = require("@hooks/user.hooks");
const core_1 = require("@web3-react/core");
require("./styles.scss");
const button_1 = require("@shared-modules/components/button");
const translation_hooks_1 = require("@hooks/translation.hooks");
const BecomeCreatorPage = () => {
    const { account } = (0, core_1.useWeb3React)();
    const { data: user, forceReload } = (0, user_hooks_1.useUserByAccount)(account || "");
    const makeRequest = (0, user_hooks_1.useCreatorRequest)();
    const makeRequestHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        yield makeRequest();
        forceReload();
    });
    const translator = (0, translation_hooks_1.usePagesTranslation)("becomeCreatorPage");
    return (React.createElement("div", { className: "p-profile-become-creator" },
        React.createElement(title_1.Title, { title: translator("title") }),
        React.createElement("p", null, translator("description")),
        React.createElement("p", null,
            (user === null || user === void 0 ? void 0 : user.minterStatus) === "minter" && translator("minterMessage"),
            (user === null || user === void 0 ? void 0 : user.minterStatus) === "requested" && translator("requestedMessage"),
            (user === null || user === void 0 ? void 0 : user.minterStatus) === "rejected" && translator("rejectedMessage")),
        (user === null || user === void 0 ? void 0 : user.minterStatus) !== "minter" && (React.createElement(button_1.Button, { onClick: makeRequestHandler, disabled: (user === null || user === void 0 ? void 0 : user.minterStatus) === "requested", type: "primary" }, translator("becomeCreatorButton")))));
};
exports.default = BecomeCreatorPage;
