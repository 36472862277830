"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventTimer = void 0;
const object_1 = require("@utils/object");
const object_2 = require("@utils/object");
const React = __importStar(require("react"));
const translation_hooks_1 = require("@hooks/translation.hooks");
require("./styles.scss");
const timer_hooks_1 = require("@shared-modules/utils/hooks/timer.hooks");
const EventTimer = (props) => {
    const { startTime, endTime, onUpdate, eventName } = props;
    const translator = (0, translation_hooks_1.usePagesTranslation)("tokenPage.eventTimer");
    const eventDate = new Date(startTime) > new Date() ? new Date(startTime) : new Date(endTime);
    const secondsForStartLeft = (0, object_2.getTimeStampInSec)(new Date(startTime)) - (0, object_2.getTimeStampInSec)();
    const isStarted = secondsForStartLeft <= 0;
    const secondsLeftToEnd = isStarted ? (0, object_2.getTimeStampInSec)(endTime) - (0, object_2.getTimeStampInSec)() : secondsForStartLeft;
    const isEnded = secondsLeftToEnd <= 0;
    const timerSeconds = (0, timer_hooks_1.useTimer)((0, object_2.getTimeStampInSec)(), secondsLeftToEnd, onUpdate);
    const duration = (0, object_1.formatDurationToObject)(timerSeconds);
    return (React.createElement("div", { className: "c-token-page-event-timer" },
        React.createElement("div", { className: "c-token-page-event-timer__event-date" },
            !isStarted ? translator("startEventLabel", { eventName }) : translator("endEventLabel", { eventName }),
            React.createElement("span", null, (0, object_1.formatHumanDateTime)(eventDate))),
        React.createElement("div", { className: "c-token-page-event-timer__duration" },
            !isEnded && (React.createElement("div", { className: "c-token-page-event-timer__time" },
                React.createElement("div", { className: "c-token-page-event-timer__time-item" },
                    Math.floor(duration.inDaysLeft),
                    React.createElement("span", null, translator("days"))),
                React.createElement("div", { className: "c-token-page-event-timer__time-item" },
                    duration.hoursLeft,
                    React.createElement("span", null, translator("hours"))),
                React.createElement("div", { className: "c-token-page-event-timer__time-item" },
                    duration.minutesLeft,
                    React.createElement("span", null, translator("minutes"))),
                React.createElement("div", { className: "c-token-page-event-timer__time-item" },
                    duration.secondsLeft,
                    React.createElement("span", null, translator("seconds"))))),
            isEnded && (React.createElement("div", { className: "c-token-page-event-timer__time-expiration" }, translator("eventEndWarning", { eventName }))))));
};
exports.EventTimer = EventTimer;
