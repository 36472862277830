"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelView = void 0;
const icons_1 = require("@shared-modules/components/icons");
const loader_1 = require("@shared-modules/components/loader");
const react_1 = __importStar(require("react"));
require("./styles.scss");
const ModelView = (props) => {
    const { metaUrlBase, metadata, onPlay } = props;
    const viewer = (0, react_1.useRef)(null);
    const loader = (0, react_1.useRef)(null);
    const playIcon = (0, react_1.useRef)(null);
    const [isError, setIsError] = (0, react_1.useState)(false);
    const errorHandler = (0, react_1.useCallback)(() => {
        if (loader.current) {
            loader.current.style.display = "none";
        }
        setIsError(true);
    }, []);
    const progressHandler = (0, react_1.useCallback)((e) => {
        if (loader.current && playIcon.current) {
            const progress = Number(e.detail.totalProgress);
            playIcon.current.style.display = "none";
            loader.current.style.display = progress < 1 ? "flex" : "none";
            const progressContainer = loader.current.querySelector(".c-nft-view-model-view__progress");
            if (progressContainer) {
                progressContainer.innerHTML = Math.floor(progress * 100) + "%";
            }
        }
    }, []);
    const loadHandler = (0, react_1.useCallback)(() => {
        if (loader.current) {
            loader.current.style.display = "none";
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (viewer.current && loader.current) {
            viewer.current.removeEventListener("progress", progressHandler);
            viewer.current.addEventListener("progress", progressHandler);
            viewer.current.removeEventListener("load", loadHandler);
            viewer.current.addEventListener("load", loadHandler);
            viewer.current.removeEventListener("error", errorHandler);
            viewer.current.addEventListener("error", errorHandler);
        }
    }, [viewer.current, loader.current]);
    const previewImageUrl = metaUrlBase + metadata.image;
    const modelUrl = metaUrlBase + metadata.model;
    const modelElement = react_1.default.createElement("model-viewer", {
        onProgress: (e) => console.log(e),
        ref: viewer,
        src: modelUrl,
        "ar-modes": "webxr scene-viewer quick-look",
        "seamless-poster": true,
        "shadow-intensity": "1",
        "camera-controls": true,
        "enable-pan": true,
        reveal: "interaction",
        ar: true,
        poster: previewImageUrl,
        "auto-rotate": true,
        children: (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { slot: "poster", className: "c-nft-view-model-view__cover", style: { backgroundImage: `url(${previewImageUrl})` } },
                react_1.default.createElement("div", { onClick: onPlay, ref: playIcon, className: "c-nft-view-model-view__play" },
                    react_1.default.createElement(icons_1.Play3DIcon, { scale: 1 }))),
            react_1.default.createElement("div", { ref: loader, className: "c-nft-view-model-view__loader", slot: "progress-bar" },
                react_1.default.createElement(loader_1.Loader, { scale: 3 }),
                react_1.default.createElement("div", { className: "c-nft-view-model-view__progress" }, "0%")))),
    });
    return (react_1.default.createElement("div", { className: "c-nft-view-model-view" }, !isError ? modelElement : react_1.default.createElement("div", { className: "c-nft-view-model-view__error" }, "3D model is unavailable")));
};
exports.ModelView = ModelView;
