"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@web3-react/core");
const react_router_dom_1 = require("react-router-dom");
const loader_1 = require("@shared-modules/components/loader");
const nft_token_card_list_1 = require("@components/nft-token-card-list");
const token_hooks_1 = require("@hooks/token.hooks");
require("./styles.scss");
const button_1 = require("@shared-modules/components/button");
const stub_cards_1 = require("@components/stub-cards");
const nft_filters_1 = require("@components/nft-filters");
const react_1 = require("react");
const translation_hooks_1 = require("@hooks/translation.hooks");
const ProfilePortfolioPage = () => {
    const { account } = (0, core_1.useWeb3React)();
    const { params: { id: profileAccount = account || "" }, } = (0, react_router_dom_1.useRouteMatch)();
    const translator = (0, translation_hooks_1.usePagesTranslation)("profilePortfolioPage");
    const commonTranslator = (0, translation_hooks_1.useCommonTranslation)();
    const [filters, setFilters] = (0, react_1.useState)();
    const { list: tokenList, isFirstLoadCompleted, loaderState, isLastPage, nextPage, } = (0, token_hooks_1.useTokenListByFilter)(Object.assign({ owner: profileAccount }, filters));
    const hasTokens = isFirstLoadCompleted && (loaderState.total || 0) > 0;
    return (React.createElement("div", { className: "p-profile-portfolio-page" },
        React.createElement("div", { className: "p-profile-portfolio-page__filters" },
            React.createElement(nft_filters_1.NftFilters, { updateFilters: setFilters })),
        !isFirstLoadCompleted && (React.createElement("div", { className: "p-profile-portfolio-page__loader" },
            React.createElement(loader_1.Loader, { scale: 1 }))),
        React.createElement("div", { className: "p-profile-portfolio-page__content-container" },
            !hasTokens && React.createElement("div", { className: "p-profile-portfolio-page__empty" }, translator("emptyList")),
            isFirstLoadCompleted && hasTokens && (React.createElement("div", { className: "p-profile-portfolio-page__content" },
                React.createElement(nft_token_card_list_1.NFTTokenCardList, { list: tokenList }),
                React.createElement(stub_cards_1.StubCards, null)))),
        !isLastPage && (React.createElement("div", { className: "p-profile-portfolio-page__button" },
            React.createElement(button_1.Button, { type: "thirtery", onClick: nextPage }, commonTranslator("loadMoreButton"))))));
};
exports.default = ProfilePortfolioPage;
