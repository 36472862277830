"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidators = exports.DEFAULT_FROM = void 0;
const validators_1 = require("@hooks/forms/validators");
exports.DEFAULT_FROM = {
    account: "",
};
const getValidators = (translator) => ({
    account: [(0, validators_1.createRequiredValidator)(translator("validation.required")), (0, validators_1.createAddressValidator)("validation.invalidAddress")],
});
exports.getValidators = getValidators;
