"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCartCleaner = exports.useGoodRemover = exports.useNewGoodAdder = exports.useCartToggler = exports.useCartPopupVisibility = exports.useCart = exports.useCartSetter = exports.setCartPopupVisibility = exports.setCart = exports.cartSlice = void 0;
const cart_service_1 = require("@app/services/cart.service");
const toolkit_1 = require("@reduxjs/toolkit");
const object_1 = require("@utils/object");
const lodash_1 = require("lodash");
const react_1 = require("react");
const actions_1 = require("../external/application/actions");
const hooks_1 = require("../hooks");
const initialState = {
    cart: [],
    isPopupVisible: false,
};
exports.cartSlice = (0, toolkit_1.createSlice)({
    name: "cart",
    initialState,
    reducers: {
        setCart: (state, action) => {
            state.cart = action.payload;
        },
        setCartPopupVisibility: (state, action) => {
            state.isPopupVisible = action.payload;
        },
    },
});
_a = exports.cartSlice.actions, exports.setCart = _a.setCart, exports.setCartPopupVisibility = _a.setCartPopupVisibility;
const useCartSetter = () => {
    const dispatch = (0, hooks_1.useAppDispatch)();
    return (0, react_1.useCallback)((list) => {
        new cart_service_1.CartService().setCart(list);
        dispatch((0, exports.setCart)(list));
    }, []);
};
exports.useCartSetter = useCartSetter;
const useCart = () => (0, hooks_1.useAppSelector)(({ cart: { cart } }) => cart);
exports.useCart = useCart;
const useCartPopupVisibility = () => (0, hooks_1.useAppSelector)(({ cart: { isPopupVisible } }) => isPopupVisible);
exports.useCartPopupVisibility = useCartPopupVisibility;
const useCartToggler = () => {
    const isPopupVisible = (0, exports.useCartPopupVisibility)();
    const dispatch = (0, hooks_1.useAppDispatch)();
    return (0, react_1.useCallback)(() => {
        dispatch((0, exports.setCartPopupVisibility)(!isPopupVisible));
    }, [isPopupVisible]);
};
exports.useCartToggler = useCartToggler;
const useNewGoodAdder = () => {
    const setCart = (0, exports.useCartSetter)();
    const dispatch = (0, hooks_1.useAppDispatch)();
    return (0, react_1.useCallback)((token, paymentAddress, price) => {
        var _a;
        const tokens = new cart_service_1.CartService().getCurrentCart() || [];
        dispatch((0, actions_1.addPopup)({
            key: new Date().getTime().toString(),
            removeAfterMs: 5000,
            content: {
                good: {
                    tokenId: token.tokenId,
                    title: token.name,
                    price,
                    tokenAddress: paymentAddress,
                    previewSrc: `${token.metaUrlBase}${(_a = token.metadata) === null || _a === void 0 ? void 0 : _a.preview}`,
                    isAdding: true,
                },
            },
        }));
        setCart((0, lodash_1.uniq)([...tokens, token.tokenId]));
    }, []);
};
exports.useNewGoodAdder = useNewGoodAdder;
const useGoodRemover = () => {
    const setCart = (0, exports.useCartSetter)();
    const dispatch = (0, hooks_1.useAppDispatch)();
    return (0, react_1.useCallback)((token, paymentAddress, price) => {
        var _a;
        const tokens = new cart_service_1.CartService().getCurrentCart() || [];
        dispatch((0, actions_1.addPopup)({
            key: new Date().getTime().toString(),
            removeAfterMs: 5000,
            content: {
                good: {
                    tokenId: token.tokenId,
                    title: token.name,
                    price,
                    tokenAddress: paymentAddress,
                    previewSrc: `${token.metaUrlBase}${(_a = token.metadata) === null || _a === void 0 ? void 0 : _a.preview}`,
                    isAdding: false,
                },
            },
        }));
        setCart(tokens.filter((tokenId) => !(0, object_1.caseInsensitiveCompare)(tokenId, token.tokenId)));
    }, []);
};
exports.useGoodRemover = useGoodRemover;
const useCartCleaner = () => {
    const setCart = (0, exports.useCartSetter)();
    return (0, react_1.useCallback)((nftIds) => {
        const tokensInCart = new cart_service_1.CartService().getCurrentCart() || [];
        const tokenIdsLeft = nftIds ? tokensInCart.filter((tokenId) => !nftIds.includes(tokenId)) : [];
        setCart(tokenIdsLeft);
        return tokenIdsLeft;
    }, []);
};
exports.useCartCleaner = useCartCleaner;
exports.default = exports.cartSlice.reducer;
