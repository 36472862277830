"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FetchInterceptor = void 0;
class FetchInterceptor {
    static register(interceptor) {
        if (interceptor.request || interceptor.requestError) {
            FetchInterceptor.requestChain.push({
                request: interceptor.request,
                requestError: interceptor.requestError,
            });
        }
        if (interceptor.response || interceptor.responseError) {
            FetchInterceptor.responseChain.push({
                response: interceptor.response,
                responseError: interceptor.responseError,
            });
        }
        const newFetch = (url, config) => {
            let fetchRequest = Promise.resolve({ url, config });
            FetchInterceptor.requestChain.forEach((listeners) => {
                fetchRequest = fetchRequest
                    // tslint:disable-next-line:max-line-length
                    .then((request) => (listeners.request ? listeners.request(request) : Promise.resolve(request)), listeners.requestError);
            });
            let fetchResponse = fetchRequest.then((request) => FetchInterceptor.originalFetch(request.url, request.config));
            FetchInterceptor.responseChain.forEach((listeners) => {
                fetchResponse = fetchResponse.then(listeners.response, listeners.responseError);
            });
            return fetchResponse;
        };
        window.fetch = newFetch;
    }
}
exports.FetchInterceptor = FetchInterceptor;
FetchInterceptor.requestChain = [];
FetchInterceptor.responseChain = [];
FetchInterceptor.originalFetch = window.fetch && window.fetch.bind(window);
