"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const hooks_1 = require("@app/store/hooks");
const identity_reducer_1 = require("@app/store/reducers/identity.reducer");
const ipfs_hooks_1 = require("@hooks/ipfs.hooks");
const routing_hooks_1 = require("@hooks/routing.hooks");
const user_hooks_1 = require("@hooks/user.hooks");
const title_1 = require("@shared-modules/components/title");
const url_builder_1 = require("@utils/url-builder");
const core_1 = require("@web3-react/core");
const React = __importStar(require("react"));
const react_1 = require("react");
const profile_edit_form_1 = require("./components/profile-edit-form");
const profile_edit_upload_photo_1 = require("./components/profile-edit-upload-photo");
const helper_1 = require("./helper");
const translation_hooks_1 = require("@hooks/translation.hooks");
require("./styles.scss");
const ProfileEditPage = () => {
    const { account } = (0, core_1.useWeb3React)();
    const { data: profile } = (0, user_hooks_1.useUserByAccount)(account || "");
    const translator = (0, translation_hooks_1.usePagesTranslation)("profileEditPage");
    const [cid, setCID] = React.useState();
    const dispatcher = (0, hooks_1.useAppDispatch)();
    const photoUploader = (0, ipfs_hooks_1.useFileUpload)();
    const updateProfile = (0, helper_1.useProfileUpdate)(account || "");
    const redirectProfile = (0, routing_hooks_1.useRedirectTo)("/profile", true);
    const updateProfileHandler = (form) => __awaiter(void 0, void 0, void 0, function* () {
        if (!profile) {
            return;
        }
        const updatedUserInfo = Object.assign(Object.assign({}, form), { avatar: cid ? (0, url_builder_1.getIPFSLink)(cid) : form.avatar });
        yield updateProfile(updatedUserInfo);
        dispatcher((0, identity_reducer_1.updateUser)(Object.assign(Object.assign({}, profile), updatedUserInfo)));
        redirectProfile();
    });
    const photoUploadHandler = (file) => __awaiter(void 0, void 0, void 0, function* () {
        const cid = yield photoUploader(file);
        setCID(cid);
    });
    const profileForm = (0, react_1.useMemo)(() => (Object.assign(Object.assign({}, helper_1.DEFAULT_FORM), profile)), [profile]);
    return (React.createElement("div", { className: "c-profile-edit-container" },
        React.createElement(title_1.Title, { title: translator("title") }),
        React.createElement(profile_edit_upload_photo_1.ProfileEditUploadPhoto, { uploadPhoto: photoUploadHandler, profile: profile }),
        React.createElement(profile_edit_form_1.ProfileEditForm, { updateForm: updateProfileHandler, profile: profileForm })));
};
exports.default = ProfileEditPage;
