"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractManager = void 0;
const config_1 = require("@app/config");
const ethers_1 = require("ethers");
const http_1 = require("@utils/http");
const object_1 = require("@utils/object");
class ContractManager {
    static loadContractsConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            if (ContractManager.configs) {
                return;
            }
            const httpClient = new http_1.HttpClient();
            const { object: scheme } = yield httpClient.get(config_1.CONFIG.contractsUrlConfig);
            ContractManager.configs = yield ContractManager.loadSchemes(JSON.parse(scheme));
        });
    }
    static createInstance() {
        return new ContractManager();
    }
    initContract(chainId, contract, signer) {
        const address = ContractManager.getCurrentContractAddress(contract, chainId);
        if (!address) {
            return undefined;
        }
        return this.initContractWithAddress(chainId, contract, signer, address);
    }
    initContractWithAddress(chainId, contract, signer, address) {
        if (chainId === undefined) {
            return undefined;
        }
        const abi = this.getContractAbi(contract, chainId, address);
        return new ethers_1.Contract(address, abi, signer);
    }
    getContractAbi(contract, chainId, address) {
        var _b, _c, _d;
        const currentVersion = address ? (_b = ContractManager.configs[chainId]) === null || _b === void 0 ? void 0 : _b.current[contract] : undefined;
        const [, abi] = Object.entries(ContractManager.configs[chainId].contracts[contract]).find(([version, abi]) => address ? (0, object_1.caseInsensitiveCompare)(address, abi.address) : (0, object_1.caseInsensitiveCompare)(currentVersion, version)) || [];
        return (abi === null || abi === void 0 ? void 0 : abi.abi) || ((_d = (_c = ContractManager.configs[chainId]) === null || _c === void 0 ? void 0 : _c.contracts[contract].default) === null || _d === void 0 ? void 0 : _d.abi) || undefined;
    }
}
exports.ContractManager = ContractManager;
_a = ContractManager;
ContractManager.getSupportedChains = () => {
    return ContractManager.configs ? Object.keys(ContractManager.configs).map((chainId) => Number(chainId)) : [];
};
ContractManager.getCurrentContractAddress = (name, chainId) => {
    var _b;
    if (chainId === undefined) {
        return undefined;
    }
    const currentVersion = (_b = ContractManager.configs[chainId]) === null || _b === void 0 ? void 0 : _b.current[name];
    return currentVersion ? ContractManager.configs[chainId].contracts[name][currentVersion].address : "";
};
ContractManager.getCurrentContractAddressByVersion = (name, version, chainId) => {
    if (chainId === undefined) {
        return undefined;
    }
    return ContractManager.configs[chainId].contracts[name][version].address;
};
ContractManager.getVersionOfContract = (name, chainId, address) => {
    if (chainId === undefined) {
        return undefined;
    }
    const [version] = Object.entries(ContractManager.configs[chainId].contracts[name]).find(([, obj]) => (0, object_1.caseInsensitiveCompare)(obj.address, address)) || [];
    return version;
};
ContractManager.loadSchemes = (scheme) => __awaiter(void 0, void 0, void 0, function* () {
    const httpClient = new http_1.HttpClient();
    return (yield Promise.all(Object.entries(scheme).map(([chain, config]) => __awaiter(void 0, void 0, void 0, function* () {
        const contracts = (yield Promise.all(Object.entries(config.contracts).map(([name, contractInfoVersion]) => __awaiter(void 0, void 0, void 0, function* () {
            const versions = (yield Promise.all(Object.entries(contractInfoVersion).map(([version, contractInfo]) => __awaiter(void 0, void 0, void 0, function* () {
                const { object: abi } = yield httpClient.get(contractInfo.abiUrl);
                return [version, Object.assign(Object.assign({}, contractInfo), { abi: JSON.parse(abi) })];
            })))).reduce((acc, [version, config]) => {
                acc[version] = config;
                return acc;
            }, {});
            return [name, versions];
        })))).reduce((acc, [name, contract]) => {
            acc[name] = contract;
            return acc;
        }, {});
        return [chain, Object.assign(Object.assign({}, config), { contracts })];
    })))).reduce((acc, [chain, config]) => {
        acc[chain] = config;
        return acc;
    }, {});
});
