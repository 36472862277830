"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("@app/config");
class EventSourceService {
    constructor() {
        this.handlerPool = [];
        this.subscribeToEvent = (eventName, action) => {
            const wrappedHandler = this.createHandlerWrapper(action);
            if (this.eventSource.readyState === this.eventSource.OPEN) {
                this.eventSource.addEventListener(eventName, wrappedHandler);
            }
            else {
                this.handlerPool.push([eventName, wrappedHandler]);
            }
            return () => this.eventSource.addEventListener(eventName, wrappedHandler);
        };
        this.close = () => this.eventSource.close();
        this.openEventHandler = () => {
            this.handlerPool.forEach(([eventName, handler]) => this.eventSource.addEventListener(eventName, handler));
            this.handlerPool = [];
        };
        this.createHandlerWrapper = (action) => (event) => {
            try {
                const { payload } = JSON.parse(event.data);
                if (!payload) {
                    throw new Error("Invalid payload");
                }
                action(payload);
            }
            catch (error) {
                console.error(error);
            }
        };
        this.eventSource = new EventSource(config_1.CONFIG.eventSourceUrl);
        this.eventSource.onopen = this.openEventHandler;
        this.eventSource.onmessage = (x) => console.log(x);
    }
}
exports.default = new EventSourceService();
