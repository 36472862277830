"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStoreService = void 0;
const config_1 = require("@app/config");
const local_storage_1 = require("@utils/local-storage");
const localization_1 = require("@utils/localization");
class LocalStoreService {
    hasReferral() {
        return Boolean(local_storage_1.LocalStorage.getValue("refAddress"));
    }
    getReferral() {
        var _a;
        return (_a = local_storage_1.LocalStorage.getValue("refAddress")) === null || _a === void 0 ? void 0 : _a.refAddress;
    }
    getSelectedLanguageOrDefault() {
        var _a;
        const selectedLanguage = (_a = local_storage_1.LocalStorage.getValue("language")) === null || _a === void 0 ? void 0 : _a.language;
        if (!selectedLanguage) {
            const supportedLanguages = Object.keys(config_1.CONFIG.localizations);
            return (0, localization_1.getDefaultLanguage)(supportedLanguages, config_1.CONFIG.defaultLocalization);
        }
        return selectedLanguage;
    }
    getLanguageLibrary() {
        var _a;
        return ((_a = local_storage_1.LocalStorage.getValue("languageLibrary")) === null || _a === void 0 ? void 0 : _a.library) || undefined;
    }
    setLanguageLibrary(library) {
        return local_storage_1.LocalStorage.updateValue("languageLibrary", { library });
    }
    setSelectedLanguage(language) {
        return local_storage_1.LocalStorage.updateValue("language", { language });
    }
    setReferral(refAddress) {
        return local_storage_1.LocalStorage.updateValue("refAddress", { refAddress });
    }
    isWalletConnectMetaAvailable() {
        return Boolean(local_storage_1.LocalStorage.getValue("walletconnect"));
    }
    removeWalletConnectMobileLinking() {
        local_storage_1.LocalStorage.remove("WALLETCONNECT_DEEPLINK_CHOICE");
    }
}
exports.LocalStoreService = LocalStoreService;
exports.default = new LocalStoreService();
