"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAudioVisualizer = void 0;
const react_1 = require("react");
const useAudioVisualizer = (canvasRef, audioRef, setting) => {
    const audioSourceRef = (0, react_1.useRef)(undefined);
    const analyserRef = (0, react_1.useRef)(undefined);
    if (!canvasRef.current || !audioRef.current) {
        return undefined;
    }
    const canvas = canvasRef.current;
    const audio = audioRef.current;
    canvas.width = setting.resolution[0];
    canvas.height = setting.resolution[1];
    const context = canvas.getContext("2d");
    if (!context) {
        return undefined;
    }
    const playHandler = () => {
        const audioContext = new AudioContext();
        if (!audioSourceRef.current || !analyserRef.current) {
            audioSourceRef.current = audioContext.createMediaElementSource(audio);
            analyserRef.current = audioContext.createAnalyser();
            audioSourceRef.current.connect(analyserRef.current);
            analyserRef.current.connect(audioContext.destination);
            analyserRef.current.fftSize = setting.fftSize;
        }
        const analyser = analyserRef.current;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        audio.play();
        animate(canvas, dataArray, analyser, context, audio, setting);
    };
    const pauseHandler = () => { };
    return [playHandler, pauseHandler];
};
exports.useAudioVisualizer = useAudioVisualizer;
const animate = (canvas, dataArray, analyser, context, audio, setting) => {
    requestAnimationFrame(() => animate(canvas, dataArray, analyser, context, audio, setting));
    analyser.getByteFrequencyData(dataArray);
    draw(canvas, dataArray, context, setting);
};
const draw = (canvas, dataArray, context, setting) => {
    let xPosForRightWave = canvas.width / 2;
    let xPosForLeftWave = canvas.width / 2;
    context.clearRect(0, 0, canvas.width, canvas.height);
    for (let index = 0; index < dataArray.length; index++) {
        const barHeight = (dataArray[index] / setting.barScale) * canvas.height + setting.minBarHeight;
        if (context.fillStyle) {
            context.fillStyle = setting.color;
            context.fillRect(xPosForRightWave, (canvas.height - barHeight) / 2, setting.barWidth, barHeight);
            context.fillRect(xPosForLeftWave, (canvas.height - barHeight) / 2, setting.barWidth, barHeight);
            xPosForRightWave += setting.barWidth + setting.barGap;
            xPosForLeftWave -= setting.barWidth + setting.barGap;
        }
    }
};
