"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMyRelation = exports.TokenRelationType = void 0;
const object_1 = require("@utils/object");
var TokenRelationType;
(function (TokenRelationType) {
    TokenRelationType[TokenRelationType["NotForSale"] = 0] = "NotForSale";
    TokenRelationType[TokenRelationType["MarketBuyer"] = 1] = "MarketBuyer";
    TokenRelationType[TokenRelationType["MarketSeller"] = 2] = "MarketSeller";
    TokenRelationType[TokenRelationType["AuctionParticipant"] = 3] = "AuctionParticipant";
    TokenRelationType[TokenRelationType["AuctionOwner"] = 4] = "AuctionOwner";
    TokenRelationType[TokenRelationType["Unknown"] = 5] = "Unknown";
    TokenRelationType[TokenRelationType["LotteryBuyer"] = 6] = "LotteryBuyer";
    TokenRelationType[TokenRelationType["LotteryOwner"] = 7] = "LotteryOwner";
})(TokenRelationType = exports.TokenRelationType || (exports.TokenRelationType = {}));
const getMyRelation = (token, isIdentified, account) => {
    const isTokenOwner = (0, object_1.caseInsensitiveCompare)(token.token.owner.wallet, account) && isIdentified;
    if (token.type === "none" && isTokenOwner && isIdentified) {
        return TokenRelationType.NotForSale;
    }
    if (token.type === "market") {
        if (isTokenOwner) {
            return TokenRelationType.MarketSeller;
        }
        else {
            return TokenRelationType.MarketBuyer;
        }
    }
    if (token.type === "lottery") {
        if (isTokenOwner) {
            return TokenRelationType.LotteryOwner;
        }
        else {
            return TokenRelationType.LotteryBuyer;
        }
    }
    if (token.type === "auction") {
        if (isTokenOwner) {
            return TokenRelationType.AuctionOwner;
        }
        else {
            return TokenRelationType.AuctionParticipant;
        }
    }
    return TokenRelationType.Unknown;
};
exports.getMyRelation = getMyRelation;
