"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTracker = exports.useBlockAwaiter = exports.useTokensInOperation = exports.useOperationByTokenId = exports.useLastSyncedBlock = exports.updateLatestSyncedBlock = exports.blockTrackerSlice = void 0;
const block_tracker_service_1 = require("@app/services/block-tracker.service");
const toolkit_1 = require("@reduxjs/toolkit");
const hooks_1 = require("../hooks");
const initialState = {
    latestSyncedBlock: 0,
    blocksForTracking: {},
};
exports.blockTrackerSlice = (0, toolkit_1.createSlice)({
    name: "blockTracker",
    initialState,
    reducers: {
        updateLatestSyncedBlock: (state, action) => {
            state.blocksForTracking = action.payload.blocksForTracking || state.blocksForTracking;
            state.latestSyncedBlock = action.payload.latestSyncedBlock || state.latestSyncedBlock;
        },
    },
});
exports.updateLatestSyncedBlock = exports.blockTrackerSlice.actions.updateLatestSyncedBlock;
const useLastSyncedBlock = () => {
    return (0, hooks_1.useAppSelector)((state) => state.blockTracker.latestSyncedBlock);
};
exports.useLastSyncedBlock = useLastSyncedBlock;
const useOperationByTokenId = (tokenId) => {
    return (0, hooks_1.useAppSelector)((state) => state.blockTracker.blocksForTracking[tokenId]);
};
exports.useOperationByTokenId = useOperationByTokenId;
const useTokensInOperation = () => {
    return (0, hooks_1.useAppSelector)((state) => Object.keys(state.blockTracker.blocksForTracking));
};
exports.useTokensInOperation = useTokensInOperation;
const useBlockAwaiter = () => {
    const blockTrackerService = new block_tracker_service_1.BlockTrackerService();
    const dispatch = (0, hooks_1.useAppDispatch)();
    return (block, tokenId, operation) => {
        blockTrackerService.addOperation(block, tokenId, operation);
        dispatch((0, exports.updateLatestSyncedBlock)({ blocksForTracking: blockTrackerService.getList() || {} }));
    };
};
exports.useBlockAwaiter = useBlockAwaiter;
const useUpdateTracker = () => {
    const dispatch = (0, hooks_1.useAppDispatch)();
    return (state) => dispatch((0, exports.updateLatestSyncedBlock)(Object.assign({}, state)));
};
exports.useUpdateTracker = useUpdateTracker;
exports.default = exports.blockTrackerSlice.reducer;
