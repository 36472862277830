"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionsExplorerPage = void 0;
const react_1 = __importStar(require("react"));
const helper_1 = require("./helper");
const form_hooks_1 = require("@hooks/form.hooks");
const collection_card_1 = require("@components/collection-card");
const translation_hooks_1 = require("@hooks/translation.hooks");
const button_1 = require("@shared-modules/components/button");
const loader_1 = require("@shared-modules/components/loader");
const collection_hooks_1 = require("@hooks/collection.hooks");
require("./styles.scss");
const autocomplete_1 = require("@shared-modules/components/autocomplete");
const stub_cards_1 = require("@components/stub-cards");
const CollectionsExplorerPage = () => {
    var _a;
    const { form: filters, fieldHandlers } = (0, form_hooks_1.useForm)({ initialData: helper_1.DEFAULT_FILTERS });
    const { data: collectionNames } = (0, collection_hooks_1.useCollectionNames)();
    const translator = (0, translation_hooks_1.usePagesTranslation)("collectionsExplorerPage");
    const commonTranslator = (0, translation_hooks_1.useCommonTranslation)();
    const options = (0, react_1.useMemo)(() => collectionNames.map((collectionName) => ({ label: collectionName, value: collectionName })), [collectionNames]);
    const { list: collections, nextPage, isFirstLoadCompleted, isLastPage, } = (0, collection_hooks_1.useCollections)({ sorting: "desc", titleQuery: (_a = filters.title) === null || _a === void 0 ? void 0 : _a.value });
    return (react_1.default.createElement("div", { className: "p-collections-explorer-page" },
        react_1.default.createElement("div", { className: "p-collections-explorer-page__filter" },
            react_1.default.createElement(autocomplete_1.Autocomplete, { label: translator("collectionNamePlaceholder"), value: filters.title, onChange: fieldHandlers.title, options: options })),
        react_1.default.createElement("div", { className: "p-collections-explorer-page__container" },
            isFirstLoadCompleted &&
                collections.map((collection) => react_1.default.createElement(collection_card_1.CollectionCard, { key: collection.id, collection: collection })),
            !isFirstLoadCompleted && (react_1.default.createElement("div", { className: "p-collections-explorer-page__loader" },
                react_1.default.createElement(loader_1.Loader, { scale: 1 }))),
            react_1.default.createElement(stub_cards_1.StubCards, null)),
        !isLastPage && (react_1.default.createElement("div", { className: "p-collections-explorer-page__button" },
            react_1.default.createElement(button_1.Button, { type: "thirtery", onClick: nextPage }, commonTranslator("loadMoreButton"))))));
};
exports.CollectionsExplorerPage = CollectionsExplorerPage;
exports.default = exports.CollectionsExplorerPage;
