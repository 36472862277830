"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NFTTokenCardList = void 0;
const React = __importStar(require("react"));
const core_1 = require("@web3-react/core");
const object_1 = require("@utils/object");
const routing_hooks_1 = require("@hooks/routing.hooks");
const market_card_1 = require("@components/market-card");
const auction_card_1 = require("@components/auction-card");
const nft_card_1 = require("@components/nft-card");
const lottery_card_1 = require("@components/lottery-card");
const NFTTokenCardList = (props) => {
    const { list } = props;
    const { account } = (0, core_1.useWeb3React)();
    const getTokenPageUrl = (tokenId) => `/nft/token/${tokenId}`;
    const createRedirectToTokenPage = (0, routing_hooks_1.useRedirectToHandler)(getTokenPageUrl);
    return (React.createElement(React.Fragment, null, list.map((listItem) => {
        const cardClickHandler = createRedirectToTokenPage(listItem.tokenId);
        const tokenPageUrl = getTokenPageUrl(listItem.tokenId);
        const isTokenOwnedByAccount = (0, object_1.caseInsensitiveCompare)(account, listItem.token.owner.wallet);
        const cardCommonProps = {
            key: listItem.tokenId,
            onClick: cardClickHandler,
            isOwner: isTokenOwnedByAccount,
            tokenPageUrl,
        };
        switch (listItem.type) {
            case "none":
                return React.createElement(nft_card_1.NFTCard, Object.assign({ nft: listItem }, cardCommonProps));
            case "market":
                return React.createElement(market_card_1.MarketCard, Object.assign({ marketPosition: listItem }, cardCommonProps));
            case "auction":
                return React.createElement(auction_card_1.AuctionCard, Object.assign({ auction: listItem }, cardCommonProps));
            case "lottery":
                return React.createElement(lottery_card_1.LotteryCard, Object.assign({ lottery: listItem }, cardCommonProps));
            default:
                return null;
        }
    })));
};
exports.NFTTokenCardList = NFTTokenCardList;
