"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftFilters = void 0;
const form_hooks_1 = require("@hooks/form.hooks");
const translation_hooks_1 = require("@hooks/translation.hooks");
const button_1 = require("@shared-modules/components/button");
const icons_1 = require("@shared-modules/components/icons");
const outside_click_hooks_1 = require("@shared-modules/utils/hooks/outside-click.hooks");
const React = __importStar(require("react"));
const react_1 = require("react");
const react_collapse_1 = require("react-collapse");
require("./styles.scss");
const getNftTypeOptions = (translator) => [
    { label: translator("type.none"), value: "none" },
    { label: translator("type.market"), value: "market" },
    { label: translator("type.auction"), value: "auction" },
    { label: translator("type.lottery"), value: "lottery" },
];
const getMediaTypeOptions = (translator) => [
    { label: translator("media.image"), value: "image" },
    { label: translator("media.audio"), value: "audio" },
    { label: translator("media.video"), value: "video" },
    { label: translator("media.3d"), value: "3d" },
];
const getSortingOptions = (translator) => [
    { label: translator("sorting.creatingAsc"), value: "creatingAsc" },
    { label: translator("sorting.creatingDesc"), value: "creatingDesc" },
];
const INITIAL_DATA = {
    types: [],
    mediaTypes: [],
    sort: undefined,
};
const NftFilters = (props) => {
    var _a;
    const { updateFilters, initial } = props;
    const [selectedFilters, setSelectedFilters] = React.useState();
    const translator = (0, translation_hooks_1.useComponentTranslation)("nftFilters");
    const updateFiltersListener = (form) => {
        var _a;
        updateFilters({
            mediaType: form.mediaTypes,
            types: form.types,
            sorting: ((_a = form.sort) === null || _a === void 0 ? void 0 : _a.includes("Desc")) ? "desc" : "asc",
            sortingProperty: "created",
        });
    };
    const initialData = (0, react_1.useMemo)(() => {
        if (!initial) {
            return INITIAL_DATA;
        }
        return {
            types: initial.types || INITIAL_DATA.types,
            mediaTypes: initial.mediaType || INITIAL_DATA.mediaTypes,
            sort: (initial.sorting && (initial.sorting === "asc" ? "creatingAsc" : "creatingDesc")) || INITIAL_DATA.sort,
        };
    }, []);
    const { form, fieldHandlers } = (0, form_hooks_1.useForm)({ initialData, onUpdate: updateFiltersListener });
    const chooseOptionHandler = (key, value) => () => {
        if (form[key].some((selectedValue) => selectedValue === value)) {
            const newOptions = form[key].filter((option) => option !== value);
            fieldHandlers[key](newOptions);
        }
        else {
            fieldHandlers[key]([...form[key], value]);
        }
    };
    const getButtonStyle = (targetType) => { var _a; return selectedFilters === targetType || (targetType && ((_a = form[targetType]) === null || _a === void 0 ? void 0 : _a.length)) || 0 > 0 ? "primary" : "thirtery"; };
    const getIconColor = (targetType) => { var _a; return selectedFilters === targetType || (targetType && ((_a = form[targetType]) === null || _a === void 0 ? void 0 : _a.length)) || 0 > 0 ? "white" : "#686868"; };
    (0, outside_click_hooks_1.useOutsideClick)(() => document.querySelector("#" + selectedFilters), () => setSelectedFilters(undefined), [selectedFilters]);
    return (React.createElement("div", { className: "c-nft-filters" },
        React.createElement("div", { className: "c-nft-filters__filters" },
            React.createElement(button_1.Button, { tag: "a", type: getButtonStyle("types"), className: "c-nft-filters__selection-filter-button", onClick: () => setSelectedFilters("types") },
                React.createElement("div", { className: "c-nft-filters__button" },
                    React.createElement(icons_1.MyItemsIcon, { scale: 0.8, color: getIconColor("types") }),
                    " ",
                    translator("nftTypeLabel"),
                    " ",
                    form.types.length ? React.createElement("span", null, form.types.length) : ""),
                React.createElement("div", { className: "c-nft-filters__filter-selection" },
                    React.createElement(react_collapse_1.UnmountClosed, { isOpened: selectedFilters === "types" },
                        React.createElement("div", { id: "types", className: "c-nft-filters__selection-container" },
                            React.createElement("div", { className: "c-nft-filters__selection-label" }, translator("selectNftTypeLabel")),
                            React.createElement("div", { className: "c-nft-filters__selection-filters" }, getNftTypeOptions(translator).map(({ value, label }) => (React.createElement(button_1.Button, { key: value, type: form.types.includes(value) ? "black" : "thirtery", onClick: chooseOptionHandler("types", value) }, label)))))))),
            React.createElement(button_1.Button, { tag: "a", type: getButtonStyle("mediaTypes"), className: "c-nft-filters__selection-filter-button", onClick: () => setSelectedFilters("mediaTypes") },
                React.createElement("div", { className: "c-nft-filters__button" },
                    React.createElement(icons_1.ShapesIcon, { color: getIconColor("mediaTypes") }),
                    " ",
                    translator("mediaTypeLabel"),
                    " ",
                    form.mediaTypes.length ? React.createElement("span", null, form.mediaTypes.length) : ""),
                React.createElement("div", { className: "c-nft-filters__filter-selection right" },
                    React.createElement(react_collapse_1.UnmountClosed, { isOpened: selectedFilters === "mediaTypes" },
                        React.createElement("div", { id: "mediaTypes", className: "c-nft-filters__selection-container" },
                            React.createElement("div", { className: "c-nft-filters__selection-label" }, translator("selectMediaTypeLabel")),
                            React.createElement("div", { className: "c-nft-filters__selection-filters" }, getMediaTypeOptions(translator).map(({ value, label }) => (React.createElement(button_1.Button, { key: value, type: form.mediaTypes.includes(value) ? "black" : "thirtery", onClick: chooseOptionHandler("mediaTypes", value) }, label))))))))),
        React.createElement("div", { className: "c-nft-filters__sorting" },
            React.createElement(button_1.Button, { tag: "a", type: getButtonStyle("sort"), className: "c-nft-filters__selection-filter-button", onClick: () => setSelectedFilters("sorting") },
                React.createElement("div", { className: "c-nft-filters__button" },
                    ((_a = getSortingOptions(translator).find(({ value }) => value === form.sort)) === null || _a === void 0 ? void 0 : _a.label) || translator("sortingLabel"),
                    " ",
                    React.createElement(icons_1.Sorting, { color: getIconColor("sort") })),
                React.createElement("div", { className: "c-nft-filters__sorting-selection" },
                    React.createElement(react_collapse_1.UnmountClosed, { isOpened: selectedFilters === "sorting" },
                        React.createElement("div", { id: "sorting", className: "c-nft-filters__sorting-list" }, getSortingOptions(translator).map(({ value, label }) => (React.createElement("div", { key: value, className: `c-nft-filters__sorting-list-item c-nft-filters__sorting-list-item--${value === form.sort ? "active" : "not-active"}`, onClick: (e) => {
                                e.stopPropagation();
                                setSelectedFilters(undefined);
                                fieldHandlers.sort(value);
                            } }, label))))))))));
};
exports.NftFilters = NftFilters;
