"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateLottery = exports.useLotteryApprove = exports.useFinalizeLottery = exports.useBuyTickets = void 0;
const _abi_1 = require("@abi");
const validator_1 = require("@app/abi/validator");
const voucher_service_1 = require("@app/services/voucher.service");
const approve_hooks_1 = require("@hooks/contracts/approve.hooks");
const contract_hooks_1 = require("@hooks/contracts/contract.hooks");
const translation_hooks_1 = require("@hooks/translation.hooks");
const object_1 = require("@utils/object");
const number_1 = require("@utils/object/number");
const core_1 = require("@web3-react/core");
const react_1 = require("react");
const voucherService = new voucher_service_1.VoucherService();
const useBuyTickets = (tokenId, address) => {
    const { account, chainId } = (0, core_1.useWeb3React)();
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const commandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCLottery", address, "buyTicket");
    return (0, react_1.useCallback)((ticketCount) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return;
        }
        const tokenVoucher = yield voucherService.getTicketsVoucher(account, tokenId);
        const summary = translator("buyTicket", {
            ticketCount,
            tokenId,
        });
        const promiseCallback = yield commandExecutor(summary, [_abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId), tokenId, ticketCount, tokenVoucher], tokenId);
        return promiseCallback();
    }), [commandExecutor, tokenId, account, address]);
};
exports.useBuyTickets = useBuyTickets;
const useFinalizeLottery = (tokenId, address, isAnyBought) => {
    const { account, chainId } = (0, core_1.useWeb3React)();
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    const finalizeCommandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCLottery", address, "finalize");
    const cancelCommandExecutor = (0, contract_hooks_1.useDefaultContractWithAddressCallback)("MCLottery", address, "cancelLottery");
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        const summary = translator("finalizeLottery", {
            tokenId,
        });
        const winnerVoucher = yield voucherService.getLotteryWinnerVoucher(tokenId);
        if (validator_1.ADDRESS_REGEX.test((winnerVoucher === null || winnerVoucher === void 0 ? void 0 : winnerVoucher.winner) || "") && isAnyBought) {
            const commandResult = yield finalizeCommandExecutor(summary, [winnerVoucher]);
            return [winnerVoucher === null || winnerVoucher === void 0 ? void 0 : winnerVoucher.winner, commandResult()];
        }
        else {
            const summary = translator("cancelLottery", {
                tokenId,
            });
            const commandResult = yield cancelCommandExecutor(summary, [_abi_1.ContractManager.getCurrentContractAddress("MCNFT", chainId), tokenId], tokenId);
            return [undefined, commandResult()];
        }
    }), [finalizeCommandExecutor, tokenId, account, cancelCommandExecutor]);
};
exports.useFinalizeLottery = useFinalizeLottery;
const useLotteryApprove = (tokenId, nftAddress) => (0, approve_hooks_1.useMCNFTApprove)(tokenId, nftAddress, "MCLottery");
exports.useLotteryApprove = useLotteryApprove;
const useCreateLottery = (tokenId) => {
    const commandExecutor = (0, contract_hooks_1.useDefaultContractCallback)("MCLottery", "createLottery");
    const translator = (0, translation_hooks_1.useComponentTranslation)("txMessages");
    return (0, react_1.useCallback)((availableTickets, ticketPrice, endDate, paymentToken) => __awaiter(void 0, void 0, void 0, function* () {
        const summary = translator("createLottery", {
            tokenId,
        });
        const voucher = yield voucherService.getLotteryVoucher(tokenId);
        const promiseCallback = yield commandExecutor(summary, [
            voucher,
            {
                endLotteryDate: (0, object_1.getTimeStampInSec)(endDate),
                availableTickets,
                ticketPrice: (0, number_1.denormalizeValue)(ticketPrice, paymentToken.decimals).toFixed(0),
                priceToken: paymentToken.address,
            },
        ], tokenId);
        return promiseCallback();
    }), [commandExecutor]);
};
exports.useCreateLottery = useCreateLottery;
