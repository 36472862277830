"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NFTCard = void 0;
const React = __importStar(require("react"));
const button_1 = require("@shared-modules/components/button");
const base_card_1 = require("@components/base-card");
require("./styles.scss");
const user_mini_profile_1 = require("@components/user-mini-profile");
const object_1 = require("@utils/object");
const react_router_dom_1 = require("react-router-dom");
const translation_hooks_1 = require("@hooks/translation.hooks");
const NFTCard = (props) => {
    const { onClick, nft, isOwner = false, tokenPageUrl } = props;
    const { token } = nft;
    const translator = (0, translation_hooks_1.useComponentTranslation)("nftCard");
    return (React.createElement(base_card_1.BaseCard, { onPreviewClick: onClick, metadata: token.metadata, baseMetaDataUrl: token.metaUrlBase, tokenPageUrl: tokenPageUrl },
        React.createElement("div", { className: "c-nft-card" },
            React.createElement("div", { className: "c-nft-card__title" }, token.name),
            React.createElement("div", { className: "c-nft-card__info" },
                React.createElement("div", { className: "c-nft-card__info-section" },
                    React.createElement("div", { className: "c-nft-card__info-section-label" }, translator("collectionNameLabel")),
                    React.createElement(react_router_dom_1.Link, { to: `/collections/${token.collectionId}`, className: "g-link c-nft-card__info-section-value" }, token.collection.title))),
            React.createElement("div", { className: "c-nft-card__footer" },
                React.createElement(user_mini_profile_1.UserMiniProfile, { title: translator("ownerLabel"), avatar: token.owner.avatar, displayName: (0, object_1.concatStringsWithWhitespace)(token.owner.firstName, token.owner.lastName), address: token.owner.wallet }),
                React.createElement(button_1.Button, { tag: "a", buttonUrl: tokenPageUrl, size: "small" }, isOwner ? translator("manageButton") : translator("openButton"))))));
};
exports.NFTCard = NFTCard;
