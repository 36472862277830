"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelSaleForm = void 0;
const tokens_reducer_1 = require("@app/store/reducers/tokens.reducer");
const block_tracker_hooks_1 = require("@hooks/block-tracker.hooks");
const market_hooks_1 = require("@hooks/contracts/market.hooks");
const button_1 = require("@shared-modules/components/button");
const number_1 = require("@utils/object/number");
const React = __importStar(require("react"));
const token_info_1 = require("../token-info");
const translation_hooks_1 = require("@hooks/translation.hooks");
require("./styles.scss");
const CancelSaleForm = (props) => {
    const { marketPosition, updateToken } = props;
    const translator = (0, translation_hooks_1.usePagesTranslation)("tokenPage.cancelSaleForm");
    const [isCancelingProcess, setIsCancelingProcess] = React.useState(false);
    const paymentToken = (0, tokens_reducer_1.useTokenByAddress)(marketPosition.paymentTokenAddress);
    const { waitBlock } = (0, block_tracker_hooks_1.useBlockTracker)(() => {
        updateToken();
        setIsCancelingProcess(false);
    });
    const cancelTokenSelling = (0, market_hooks_1.useCancelSale)(marketPosition.tokenId, marketPosition === null || marketPosition === void 0 ? void 0 : marketPosition.contractAddress);
    const cancelHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsCancelingProcess(true);
        try {
            const { blockNumber } = (yield cancelTokenSelling()) || {};
            waitBlock(blockNumber);
        }
        catch (error) {
            setIsCancelingProcess(false);
            console.error(error);
        }
    });
    const marketPositionInfo = [
        {
            label: translator("priceLabel"),
            currencySymbol: paymentToken === null || paymentToken === void 0 ? void 0 : paymentToken.symbol,
            value: (0, number_1.formatAndNormalizeToken)(paymentToken, marketPosition.fixPrice, paymentToken === null || paymentToken === void 0 ? void 0 : paymentToken.decimals),
        },
        {
            label: translator("royaltiesLabel"),
            value: (0, number_1.formatBigNumber)(marketPosition.token.royaltyFee) + "%",
        },
    ];
    return (React.createElement("div", { className: "c-token-page-cancel-sale-form" },
        React.createElement(token_info_1.TokenInfo, { cells: marketPositionInfo }),
        React.createElement("div", { className: "c-token-page-cancel-sale-form__button" },
            React.createElement(button_1.Button, { loading: isCancelingProcess, disabled: isCancelingProcess, onClick: cancelHandler }, translator("cancelButton")))));
};
exports.CancelSaleForm = CancelSaleForm;
