"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createValidators = void 0;
const number_1 = require("@utils/object/number");
const validators_1 = require("@hooks/forms/validators");
const createValidators = (minBid, balance, translator, hasBids) => {
    return {
        bid: [
            (0, validators_1.createRequiredValidator)(translator("validation.required")),
            (hasBids ? validators_1.createGreatThanValidator : validators_1.createGreatOrEqualThanValidator)(minBid, hasBids
                ? translator(translator("validation.greatThan", { boundary: (0, number_1.formatBigNumber)(minBid) }))
                : translator("validation.greatOrEqualThan", { boundary: (0, number_1.formatBigNumber)(minBid) })),
            (0, validators_1.createLessOrEqualThanValidator)(balance, translator("validation.balanceNotEnough")),
        ],
    };
};
exports.createValidators = createValidators;
