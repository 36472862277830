"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionService = void 0;
const config_1 = require("@app/config");
const _dictionaries_1 = require("@dictionaries");
const http_1 = require("@utils/http");
const http_service_1 = require("./http.service");
class CollectionService extends http_service_1.HttpService {
    constructor() {
        super(config_1.CONFIG.baseApiUrl);
        this.getCollections = (filters, page = 1, limit = 20) => __awaiter(this, void 0, void 0, function* () {
            const query = (0, http_1.parseToUrlQuery)(Object.assign(Object.assign({}, filters), { page, limit }));
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getCollections(query));
            return object;
        });
        this.getCollectionById = (id) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getCollectionById(id));
            return object;
        });
        this.getCollectionStats = (id) => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getCollectionStats(id));
            return object;
        });
        this.getCollectionNames = () => __awaiter(this, void 0, void 0, function* () {
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getCollectionNames());
            return object;
        });
        this.createCollection = (title, description, coverUrl, previewUrl, creator, jwt) => __awaiter(this, void 0, void 0, function* () {
            const headers = this.attachJwt(jwt);
            yield this.httpClient.post(_dictionaries_1.API_ROUTES.createCollection(), {
                body: JSON.stringify({
                    title,
                    description,
                    coverUrl,
                    previewUrl,
                    creator,
                }),
                headers,
            });
        });
        this.updateCollection = (id, title, description, coverUrl, previewUrl, creator, jwt) => __awaiter(this, void 0, void 0, function* () {
            const headers = this.attachJwt(jwt);
            yield this.httpClient.put(_dictionaries_1.API_ROUTES.updateCollection(id), {
                body: JSON.stringify({
                    title,
                    description,
                    coverUrl,
                    previewUrl,
                    creator,
                }),
                headers,
            });
        });
        this.getRating = (period, page = 1, limit = 20) => __awaiter(this, void 0, void 0, function* () {
            const query = (0, http_1.parseToUrlQuery)({ page, limit, period }, true);
            const { object } = yield this.httpClient.get(_dictionaries_1.API_ROUTES.getCollectionRating(query));
            return object;
        });
    }
}
exports.CollectionService = CollectionService;
