"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseCodes = void 0;
var ResponseCodes;
(function (ResponseCodes) {
    ResponseCodes[ResponseCodes["Ok"] = 1000] = "Ok";
    ResponseCodes[ResponseCodes["Invalid"] = 2000] = "Invalid";
    ResponseCodes[ResponseCodes["InvalidLoginOrPassword"] = 2001] = "InvalidLoginOrPassword";
    ResponseCodes[ResponseCodes["ImposableLogin"] = 2002] = "ImposableLogin";
})(ResponseCodes = exports.ResponseCodes || (exports.ResponseCodes = {}));
