"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDefaultContractWithAddressCallback = exports.useDefaultContractCallback = exports.useContractWithAddress = exports.useContract = void 0;
const react_1 = require("react");
const core_1 = require("@web3-react/core");
const utils_1 = require("./utils");
const _abi_1 = require("@abi");
const hooks_1 = require("@app/store/external/transactions/hooks");
const hooks_2 = require("@app/store/external/application/hooks");
const error_logger_1 = require("@utils/exception/error-logger");
const block_tracking_reducer_1 = require("@app/store/reducers/block-tracking.reducer");
const useContract = (contract, chainId) => {
    return (0, exports.useContractWithAddress)(contract, chainId, _abi_1.ContractManager.getCurrentContractAddress(contract, chainId));
};
exports.useContract = useContract;
const useContractWithAddress = (contract, chainId, address) => {
    const { library } = (0, core_1.useWeb3React)();
    return (0, react_1.useMemo)(() => {
        try {
            if (address && library) {
                return _abi_1.ContractManager.createInstance().initContractWithAddress(chainId, contract, library.getSigner(), address);
            }
            else {
                console.error("Address or library was not found");
            }
        }
        catch (error) {
            console.error("Failed to get contract", error);
        }
    }, [address, contract, library]);
};
exports.useContractWithAddress = useContractWithAddress;
const useDefaultContractCallback = (contract, fnName) => useContractCallback(contract, undefined, fnName);
exports.useDefaultContractCallback = useDefaultContractCallback;
const useDefaultContractWithAddressCallback = (contractType, address, fnName) => useContractCallback(contractType, address || "", fnName);
exports.useDefaultContractWithAddressCallback = useDefaultContractWithAddressCallback;
const useContractCallback = (contractType, address, fnName) => {
    const { chainId } = (0, core_1.useWeb3React)();
    const contract = address === undefined ? (0, exports.useContract)(contractType, chainId) : (0, exports.useContractWithAddress)(contractType, chainId, address);
    const [addTransaction, handleTransactionError] = (0, hooks_1.useTransactionAdder)();
    const walletInteractionSetter = (0, hooks_2.useSetWalletInteractions)();
    const awaitBlock = (0, block_tracking_reducer_1.useBlockAwaiter)();
    return (0, react_1.useCallback)((summary, txParams = [], blockAwaiterKey) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            walletInteractionSetter.setPending(summary);
            const estimatedGas = yield (contract === null || contract === void 0 ? void 0 : contract.estimateGas[fnName](...txParams));
            const tx = yield (contract === null || contract === void 0 ? void 0 : contract[fnName](...txParams, {
                gasLimit: (0, utils_1.getExtraGas)(estimatedGas),
            }));
            addTransaction(tx, {
                summary,
            });
            return () => Promise.resolve(tx.wait(1)).then((metadata) => {
                blockAwaiterKey ? awaitBlock(metadata.blockNumber, blockAwaiterKey, fnName) : undefined;
                return metadata;
            });
        }
        catch (e) {
            (0, error_logger_1.logError)(e, ["contracts", contractType, fnName], txParams);
            handleTransactionError(e);
            throw e;
        }
    }), [contract, chainId]);
};
