"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const collection_hooks_1 = require("@hooks/collection.hooks");
const translation_hooks_1 = require("@hooks/translation.hooks");
const form_hooks_1 = require("@hooks/form.hooks");
const ipfs_hooks_1 = require("@hooks/ipfs.hooks");
const button_1 = require("@shared-modules/components/button");
const icons_1 = require("@shared-modules/components/icons");
const loader_1 = require("@shared-modules/components/loader");
const profile_avatar_1 = require("@shared-modules/components/profile-avatar");
const text_input_1 = require("@shared-modules/components/text-input");
const textarea_input_1 = require("@shared-modules/components/textarea-input");
const object_1 = require("@utils/object");
const url_builder_1 = require("@utils/url-builder");
const React = __importStar(require("react"));
const react_1 = require("react");
require("./styles.scss");
const MAX_SIZE = 2 * 1024 * 1024;
const IMG_TYPES = /\.(jpg|jpeg|png|gif|webp)$/;
const EditCollectionForm = (props) => {
    const { closeForm, collection, tradedTokensCount } = props;
    const updateCollection = (0, collection_hooks_1.useUpdateCollection)(collection.id);
    const fileUpload = (0, ipfs_hooks_1.useFileUpload)();
    const translator = (0, translation_hooks_1.usePagesTranslation)("collectionExplorerPage.editCollectionForm");
    const [showAvatarLoader, setAvatarLoader] = (0, react_1.useState)(false);
    const [showCoverLoader, setCoverLoader] = (0, react_1.useState)(false);
    const { form, fieldHandlers, submit } = (0, form_hooks_1.useForm)({
        initialData: {
            title: collection.title || "",
            description: collection.description || "",
            avatar: collection.previewUrl || "",
            cover: collection.coverUrl || "",
        },
        submit: (form) => __awaiter(void 0, void 0, void 0, function* () {
            yield updateCollection(form.title, form.description, form.cover, form.avatar);
            closeForm();
        }),
    });
    const creatorName = collection.creator.firstName || collection.creator.lastName
        ? (0, object_1.concatStringsWithWhitespace)(collection.creator.firstName, collection.creator.lastName)
        : translator("unknownCreatorLabel");
    const createUploadFileToIPFSHandler = (key) => (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const img = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (!img || !img.name.match(IMG_TYPES)) {
            throw new Error("wrong image type");
        }
        if (img.size > MAX_SIZE) {
            throw new Error("wrong image size");
        }
        if (key === "avatar") {
            setAvatarLoader(true);
        }
        else if (key === "cover") {
            setCoverLoader(true);
        }
        try {
            const cid = yield fileUpload(img);
            fieldHandlers[key]((0, url_builder_1.getIPFSLink)(cid));
        }
        catch (error) { }
        setAvatarLoader(false);
        setCoverLoader(false);
    });
    return (React.createElement("div", { className: "c-collection-explorer-edit-collection-form" },
        React.createElement("div", { className: "c-collection-explorer-edit-collection-form__cover", style: {
                backgroundImage: `${showCoverLoader ? "linear-gradient(black, black)," : ""} url(${form.cover})`,
            } },
            React.createElement("div", { style: {
                    backgroundImage: `${showAvatarLoader ? "linear-gradient(black, black)," : ""} url(${form.avatar})`,
                }, className: "c-collection-explorer-edit-collection-form__avatar" }, showAvatarLoader ? (React.createElement(loader_1.Loader, { scale: 1.5 })) : (React.createElement(button_1.Button, { type: "transparent" },
                React.createElement("label", { className: "c-collection-explorer-edit-collection-form__file-label" },
                    React.createElement("input", { onChange: createUploadFileToIPFSHandler("avatar"), type: "file", className: "c-collection-explorer-edit-collection-form__file-input" }),
                    React.createElement(icons_1.UploadFileIcon, { scale: 1.6, color: "#272727" }),
                    React.createElement("span", null, translator("uploadRestrictionLabel", { restriction: "2MB" })))))),
            showCoverLoader ? (React.createElement(loader_1.Loader, { scale: 2 })) : (React.createElement(button_1.Button, { type: "transparent" },
                React.createElement("label", { className: "c-collection-explorer-edit-collection-form__file-label" },
                    React.createElement("input", { type: "file", onChange: createUploadFileToIPFSHandler("cover"), className: "c-collection-explorer-edit-collection-form__file-input" }),
                    React.createElement(icons_1.UploadFileIcon, { scale: 1.6 }),
                    React.createElement("span", null, translator("uploadRestrictionLabel", { restriction: "2MB" })))))),
        React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info" },
            React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-title" },
                React.createElement(text_input_1.TextInput, { label: translator("titleLabel"), placeholder: translator("titlePlaceholder"), value: form.title, handleChange: fieldHandlers.title })),
            React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-description" },
                React.createElement(textarea_input_1.TextareaInput, { label: translator("descriptionLabel"), placeholder: translator("descriptionPlaceholder"), value: form.description, handleChange: fieldHandlers.description })),
            React.createElement("div", { className: "c-collection-explorer-edit-collection-form__owner" },
                React.createElement(profile_avatar_1.ProfileAvatar, { width: "72px", height: "72px", src: collection.creator.avatar }),
                React.createElement("div", { className: "c-collection-explorer-edit-collection-form__owner-info" },
                    React.createElement("div", { className: "c-collection-explorer-edit-collection-form__fullname" }, creatorName),
                    React.createElement("div", { className: "c-collection-explorer-edit-collection-form__owner-account" }, (0, object_1.formatAddress)(collection.creator.wallet)))),
            React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-grid" },
                React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-item" },
                    React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-label" }, translator("ownerAmountLabel")),
                    React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-value" }, collection.ownersAmount)),
                React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-item" },
                    React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-label" }, translator("tradedTokensCountLabel")),
                    React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-value" }, tradedTokensCount)),
                React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-item" },
                    React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-label" }, translator("itemsInCollectionLabel")),
                    React.createElement("div", { className: "c-collection-explorer-edit-collection-form__collection-info-value" },
                        collection.tokensAmount,
                        " NFTs")),
                React.createElement(button_1.Button, { type: "secondary", onClick: closeForm }, translator("closeButton")),
                React.createElement(button_1.Button, { onClick: submit }, translator("saveButton"))))));
};
exports.default = EditCollectionForm;
