"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPeriodsOptions = exports.DEFAULT_FILTER_FORM = void 0;
exports.DEFAULT_FILTER_FORM = {
    period: undefined
};
const getPeriodsOptions = (commonTranslator, translator) => [
    {
        label: "24" + commonTranslator("time.hourShortLabel"),
        value: 24 * 60 * 60,
    },
    {
        label: "7" + commonTranslator("time.dayShortLabel"),
        value: 7 * 24 * 60 * 60,
    },
    {
        label: "30" + commonTranslator("time.dayShortLabel"),
        value: 30 * 24 * 60 * 60,
    },
    {
        label: translator("allFilterLabel"),
        value: undefined,
    },
];
exports.getPeriodsOptions = getPeriodsOptions;
