"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpClient = void 0;
const exception_1 = require("@utils/exception");
class HttpClient {
    constructor(baseUrl, extractor) {
        this.baseUrl = baseUrl;
        this.extractor = extractor;
    }
    get(request, params) {
        const requestParams = Object.assign(Object.assign({}, params), { method: "GET" });
        return this.request(request, requestParams);
    }
    post(request, params) {
        const requestParams = Object.assign(Object.assign({}, params), { method: "POST" });
        return this.request(request, requestParams);
    }
    put(request, params) {
        const requestParams = Object.assign(Object.assign({}, params), { method: "PUT" });
        return this.request(request, requestParams);
    }
    delete(request, params) {
        const requestParams = Object.assign(Object.assign({}, params), { method: "DELETE" });
        return this.request(request, requestParams);
    }
    patch(request, params) {
        const requestParams = Object.assign(Object.assign({}, params), { method: "PATCH" });
        return this.request(request, requestParams);
    }
    request(request, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = {
                headers: new Headers(),
                statusCode: 0,
            };
            let originalResponse;
            try {
                originalResponse = yield fetch((this.baseUrl || "") + request, params);
            }
            catch (error) {
                throw new exception_1.HttpOperationError(request, "Request failed", error);
            }
            if (originalResponse) {
                response.headers = originalResponse.headers;
                response.statusCode = originalResponse.status;
                const contentType = originalResponse.headers.get("content-type");
                const isJsonResponse = contentType && contentType.includes("application") && contentType.includes("json");
                const responseClone = originalResponse.clone();
                const bodyBlob = yield responseClone.clone().blob();
                const isContainsPayload = bodyBlob.size > 0;
                if (isJsonResponse && isContainsPayload) {
                    try {
                        const responseObject = yield responseClone.clone().json();
                        response.object = (this.extractor ? this.extractor(responseObject) : responseObject);
                    }
                    catch (error) {
                        const text = (yield responseClone.text()).substr(0, 500);
                        throw new exception_1.InvalidCastError("Invalid json casting", { text, request }, error);
                    }
                }
                else if (!isJsonResponse) {
                    response.object = yield responseClone.text();
                }
            }
            return response;
        });
    }
}
exports.HttpClient = HttpClient;
