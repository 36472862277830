"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyForm = void 0;
const block_tracker_hooks_1 = require("@hooks/block-tracker.hooks");
const approve_hooks_1 = require("@hooks/contracts/approve.hooks");
const market_hooks_1 = require("@hooks/contracts/market.hooks");
const button_1 = require("@shared-modules/components/button");
const number_1 = require("@utils/object/number");
const React = __importStar(require("react"));
const token_info_1 = require("../token-info");
const translation_hooks_1 = require("@hooks/translation.hooks");
require("./styles.scss");
const icons_1 = require("@shared-modules/components/icons");
const cart_reducer_1 = require("@app/store/reducers/cart.reducer");
const BuyForm = (props) => {
    const { marketPosition, updateToken } = props;
    const translator = (0, translation_hooks_1.usePagesTranslation)("tokenPage.buyForm");
    const commonTranslator = (0, translation_hooks_1.useCommonTranslation)();
    const [isBuyingProcess, setBuyingProcess] = React.useState(false);
    const paymentTokenBalance = (0, approve_hooks_1.useTokenBalance)(marketPosition.paymentTokenAddress);
    const { waitBlock } = (0, block_tracker_hooks_1.useBlockTracker)(() => {
        updateToken();
        setBuyingProcess(false);
    });
    const [approvalState, approveCallback] = (0, approve_hooks_1.useApprove)(marketPosition.paymentTokenAddress, marketPosition === null || marketPosition === void 0 ? void 0 : marketPosition.contractAddress);
    const isInProgress = approvalState === approve_hooks_1.ApprovalState.Unknown || approvalState === approve_hooks_1.ApprovalState.Pending;
    const isApproved = approvalState === approve_hooks_1.ApprovalState.Approved;
    const buyNFT = (0, market_hooks_1.useBuyingNFT)(marketPosition.tokenId, marketPosition === null || marketPosition === void 0 ? void 0 : marketPosition.contractAddress);
    const removeFromCart = (0, cart_reducer_1.useGoodRemover)();
    const addIntoCart = (0, cart_reducer_1.useNewGoodAdder)();
    const cart = (0, cart_reducer_1.useCart)();
    const isInCart = cart.includes(marketPosition.tokenId);
    const buyNFTHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setBuyingProcess(true);
        try {
            const { blockNumber } = (yield buyNFT()) || {};
            waitBlock(blockNumber);
        }
        catch (error) {
            console.error(error);
            setBuyingProcess(false);
        }
    });
    const marketPositionInfo = [
        {
            label: translator("priceLabel"),
            currencySymbol: paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.symbol,
            value: (0, number_1.formatAndNormalizeToken)(paymentTokenBalance, marketPosition.fixPrice, paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.decimals),
        },
        {
            label: translator("royaltyFeeLabel"),
            value: (0, number_1.formatBigNumber)(marketPosition.token.royaltyFee) + "%",
        },
    ];
    const isBalanceNotEnough = paymentTokenBalance === null || paymentTokenBalance === void 0 ? void 0 : paymentTokenBalance.balance.isLessThan(marketPosition.fixPrice);
    return (React.createElement("div", { className: "c-token-page-buy-form" },
        React.createElement(token_info_1.TokenInfo, { cells: marketPositionInfo }),
        React.createElement("div", { className: "c-token-page-buy-form__buy-form" },
            React.createElement(button_1.Button, { loading: isInProgress, type: "thirtery", disabled: isApproved || isInProgress, onClick: approveCallback }, commonTranslator("approveButton")),
            React.createElement(button_1.Button, { loading: isBuyingProcess, disabled: !isApproved || isBuyingProcess, onClick: buyNFTHandler }, translator("buyButton")),
            React.createElement("div", { onClick: () => !isBuyingProcess &&
                    (isInCart
                        ? removeFromCart(marketPosition.token, marketPosition.paymentTokenAddress, marketPosition.fixPrice)
                        : addIntoCart(marketPosition.token, marketPosition.paymentTokenAddress, marketPosition.fixPrice)), className: "c-token-page-buy-form__cart-button" }, isInCart ? (React.createElement(icons_1.RemoveCartIcon, { scale: 0.9, color: !isBuyingProcess ? "red" : "grey" })) : (React.createElement(icons_1.AddToCartIcon, { scale: 0.9, color: !isBuyingProcess ? "red" : "grey" })))),
        isBalanceNotEnough && (React.createElement("div", { className: "c-token-page-buy-form__validation" }, commonTranslator("validation.balanceNotEnough")))));
};
exports.BuyForm = BuyForm;
