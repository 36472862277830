"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultList = void 0;
const getDefaultList = () => ({
    list: [],
    total: 0,
    page: 0,
    size: 0,
});
exports.getDefaultList = getDefaultList;
