"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenSelect = void 0;
const React = __importStar(require("react"));
require("./styles.scss");
const select_1 = require("@shared-modules/components/select");
const tokens_reducer_1 = require("@app/store/reducers/tokens.reducer");
const react_1 = require("react");
const token_icon_1 = require("@shared-modules/components/token-icon");
const object_1 = require("@utils/object");
const TokenSelect = (props) => {
    const { label, value, onChange, error } = props;
    const tokenList = (0, tokens_reducer_1.useTokenListForCurrentChain)();
    const renderTokenOption = (address) => {
        const token = tokenList.find((token) => token.address === address);
        return (React.createElement("div", { className: "c-token-select__option" },
            React.createElement(token_icon_1.TokenIcon, { symbol: token === null || token === void 0 ? void 0 : token.symbol }), token === null || token === void 0 ? void 0 :
            token.symbol));
    };
    const selectedOptionRender = (address) => {
        const token = tokenList.find((token) => (0, object_1.caseInsensitiveCompare)(token.address, address));
        return (React.createElement("div", { className: "c-token-select__selected-option" },
            React.createElement(token_icon_1.TokenIcon, { symbol: token === null || token === void 0 ? void 0 : token.symbol }), token === null || token === void 0 ? void 0 :
            token.symbol));
    };
    const options = tokenList.map(({ address }) => ({ label: address, value: address }));
    const changeValueHandler = (0, react_1.useCallback)((address) => {
        console.log(address);
        onChange === null || onChange === void 0 ? void 0 : onChange(address ? tokenList.find((token) => (0, object_1.caseInsensitiveCompare)(token.address, address)) : undefined);
    }, [tokenList]);
    return (React.createElement(select_1.Select, { label: label, value: value === null || value === void 0 ? void 0 : value.address, optionRender: renderTokenOption, selectedOptionRender: selectedOptionRender, onChange: changeValueHandler, options: options, error: error }));
};
exports.TokenSelect = TokenSelect;
