"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const routes_1 = require("./routes");
const public_routes_1 = require("@shared-modules/components/public-routes");
const profile_sidebar_1 = require("@components/profile-sidebar");
const core_1 = require("@web3-react/core");
const profile_banner_1 = require("@components/profile-banner");
const react_router_dom_1 = require("react-router-dom");
const translation_hooks_1 = require("@hooks/translation.hooks");
const object_1 = require("@utils/object");
const application_state_reducer_1 = require("@app/store/reducers/application-state.reducer");
const button_1 = require("@shared-modules/components/button");
const title_1 = require("@shared-modules/components/title");
require("./styles.scss");
const ProfilePage = () => {
    const { account } = (0, core_1.useWeb3React)();
    const walletPopupToggle = (0, application_state_reducer_1.useWalletPopupStateToggle)();
    const translator = (0, translation_hooks_1.usePagesTranslation)("profilePage");
    const { params: { id: userAccount = account || "" }, } = (0, react_router_dom_1.useRouteMatch)();
    if (!userAccount) {
        return (React.createElement("div", { className: "p-profile-main__greeting" },
            React.createElement(title_1.Title, { tag: "h2", title: translator("noProfileLabel") }),
            React.createElement(button_1.Button, { onClick: () => walletPopupToggle(true) }, translator("joinToUsButton"))));
    }
    return (React.createElement("div", { className: "p-profile-main" },
        React.createElement(profile_banner_1.ProfileBanner, { account: userAccount }),
        React.createElement("div", { className: "p-profile-main__nav" },
            React.createElement(profile_sidebar_1.ProfileSidebar, { isOwner: (0, object_1.caseInsensitiveCompare)(account, userAccount), account: userAccount })),
        React.createElement("div", { className: "p-profile-main__content" },
            React.createElement(public_routes_1.PublicRoutes, { routes: routes_1.PROFILES_ROUTES }))));
};
exports.default = ProfilePage;
