"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withAccountConfirmation = void 0;
const react_1 = __importStar(require("react"));
const auth_service_1 = require("@app/services/auth.service");
const identity_reducer_1 = require("@app/store/reducers/identity.reducer");
const core_1 = require("@web3-react/core");
const object_1 = require("@utils/object");
const authService = new auth_service_1.AuthService();
const withAccountConfirmation = (WrappedComponent) => {
    return (props) => {
        const { account } = (0, core_1.useWeb3React)();
        const user = (0, identity_reducer_1.useCurrentUser)();
        (0, react_1.useEffect)(() => {
            if (user && account && (0, object_1.caseInsensitiveCompare)(account, user.wallet) && !user.isConfirmed) {
                authService.requestNonce(account);
            }
        }, [user === null || user === void 0 ? void 0 : user.wallet]);
        return react_1.default.createElement(WrappedComponent, Object.assign({}, props));
    };
};
exports.withAccountConfirmation = withAccountConfirmation;
