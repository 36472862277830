"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notification = void 0;
const config_1 = require("@app/config");
const translation_hooks_1 = require("@hooks/translation.hooks");
const icons_1 = require("@shared-modules/components/icons");
const core_1 = require("@web3-react/core");
const react_1 = __importStar(require("react"));
require("./styles.scss");
const Notification = (props) => {
    const { remove, removeAfterMs, popKey, summary, success, hash } = props;
    const translator = (0, translation_hooks_1.useComponentTranslation)("notifications.completed");
    const { chainId } = (0, core_1.useWeb3React)();
    const scannerUrl = config_1.CONFIG.scannerUrl[(chainId === null || chainId === void 0 ? void 0 : chainId.toString()) || ""];
    (0, react_1.useEffect)(() => {
        setTimeout(() => remove(popKey), removeAfterMs);
    }, []);
    return (react_1.default.createElement("div", { className: "c-notification" },
        react_1.default.createElement("div", { className: "c-notification__icons" }, success ? react_1.default.createElement(icons_1.SuccessIcon, null) : react_1.default.createElement(icons_1.FailIcon, null)),
        react_1.default.createElement("div", { className: "c-notification__summary" },
            react_1.default.createElement("div", { className: `c-notification__title c-notification__title--${success ? "success" : "fail"}` }, translator(success ? "transactionCompletedLabel" : "transactionFailedLabel")),
            react_1.default.createElement("div", null, summary),
            react_1.default.createElement("a", { target: "_blank", rel: "noreferrer", href: scannerUrl + "/tx/" + hash }, translator("openScannerLinkLabel")))));
};
exports.Notification = Notification;
