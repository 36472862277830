"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseToUrlQuery = exports.parseQuery = void 0;
function parseQuery(query) {
    const queryObject = {};
    if (query.length > 1) {
        const pairs = query.substring(1).split("&");
        pairs.forEach((pair) => {
            const keyValues = pair.split("=");
            const key = keyValues.shift();
            const value = keyValues.join("=");
            if (key) {
                queryObject[key] = decodeURIComponent(value);
            }
        });
    }
    return queryObject;
}
exports.parseQuery = parseQuery;
function parseToUrlQuery(object, ignoreEmpty = false) {
    return ("?" +
        Object.keys(object)
            .map((key) => {
            let urlPart = "";
            if (object[key] != null && object[key] !== "") {
                if (Array.isArray(object[key])) {
                    urlPart = parseArrayToUrlQueryPart(key, object[key]);
                }
                else {
                    urlPart = !ignoreEmpty || Boolean(object[key]) ? `${key}=${encodeURIComponent(object[key])}` : "";
                }
            }
            return urlPart;
        })
            .filter((urlPart) => Boolean(urlPart))
            .join("&"));
}
exports.parseToUrlQuery = parseToUrlQuery;
function parseArrayToUrlQueryPart(key, array) {
    return array
        .map((element) => (element != null && element !== "" ? `${key}=${encodeURIComponent(element)}` : ""))
        .filter((urlPart) => Boolean(urlPart))
        .join("&");
}
