"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTokenListByFilter = exports.useTokenById = exports.useTokenCreate = exports.useTokenHistory = exports.useAuctionTracker = exports.useAuctionBidVoucher = void 0;
const token_service_1 = require("../services/token.service");
const react_1 = require("react");
const auth_service_1 = require("../services/auth.service");
const core_1 = require("@web3-react/core");
const logger_hooks_1 = require("./logger.hooks");
const data_loader_hooks_1 = require("./data-loader.hooks");
const utils_1 = require("./utils");
const voucher_service_1 = require("@app/services/voucher.service");
const authService = new auth_service_1.AuthService();
const tokenService = new token_service_1.TokenService();
const voucherService = new voucher_service_1.VoucherService();
const useAuctionBidVoucher = () => {
    const { library, account } = (0, core_1.useWeb3React)();
    const errorHandler = (0, logger_hooks_1.useErrorMessageByCode)();
    return (0, react_1.useCallback)((tokenId, amount) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected);
            return;
        }
        try {
            return yield voucherService.getAuctionBidVoucher(tokenId, account, amount.toFixed(0));
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.GettingAuctionBidVoucherError, error);
        }
    }), [library, account]);
};
exports.useAuctionBidVoucher = useAuctionBidVoucher;
const useAuctionTracker = (reload) => (0, react_1.useEffect)(() => tokenService.subscribeOnTokenChanges(reload));
exports.useAuctionTracker = useAuctionTracker;
const useTokenHistory = (tokenId, filters, pageSize = 10) => {
    const errorHandler = (0, logger_hooks_1.useErrorMessageByCode)();
    return (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            return yield tokenService.getTokenHistory(tokenId, filters, page, size);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.ApiRequestError, error);
        }
        return (0, utils_1.getDefaultList)();
    }), [], {
        page: 1,
        size: pageSize,
        preSave: false,
    });
};
exports.useTokenHistory = useTokenHistory;
const useTokenCreate = () => {
    const { library, account } = (0, core_1.useWeb3React)();
    const errorHandler = (0, logger_hooks_1.useErrorMessageByCode)();
    return (0, react_1.useCallback)((token) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected);
            return;
        }
        let signature = "";
        try {
            signature = yield authService.getJwt(library, account);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.NotConnected, error);
        }
        try {
            yield tokenService.createToken(token, signature);
        }
        catch (error) {
            errorHandler(logger_hooks_1.ErrorCode.CreateTokenError, error);
        }
    }), [library, account]);
};
exports.useTokenCreate = useTokenCreate;
const useTokenById = (tokenId) => (0, data_loader_hooks_1.useDataLoader)(() => __awaiter(void 0, void 0, void 0, function* () {
    return tokenId ? yield tokenService.getTokenById(tokenId) : undefined;
}), [tokenId], undefined);
exports.useTokenById = useTokenById;
const useTokenListByFilter = (filter, state) => (0, data_loader_hooks_1.usePaginatedDataLoader)(({ page, size }) => tokenService.getTokenListByFilter(filter, page, size), [JSON.stringify(filter)], Object.assign({ page: 1, size: 10 }, state));
exports.useTokenListByFilter = useTokenListByFilter;
