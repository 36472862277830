"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInput = void 0;
const handlers_1 = require("@shared-modules/utils/handlers");
const outside_click_hooks_1 = require("@shared-modules/utils/hooks/outside-click.hooks");
const react_1 = __importStar(require("react"));
const react_collapse_1 = require("react-collapse");
require("./styles.scss");
const TextInput = (props) => {
    const { name, className = "", label, handleChange, value, placeholder, onClick, type = "string", error, autocompleteOptions = [], decimals, expandAutocompleteOnClick, } = props;
    const onChange = (e) => {
        switch (type) {
            case "positiveNumber":
                handleChange && (0, handlers_1.positiveNumericFieldHandler)(e.target.value, handleChange, decimals);
                break;
            case "positiveInteger":
                handleChange && (0, handlers_1.positiveIntegerFieldHandler)(e.target.value, handleChange);
                break;
            case "string":
                handleChange === null || handleChange === void 0 ? void 0 : handleChange(e.target.value);
                setAutocompleteState(e.target.value.length > 0);
                break;
        }
    };
    const refContainer = (0, react_1.useRef)(null);
    const refInput = (0, react_1.useRef)(null);
    const [isAutocompleteOpened, setAutocompleteState] = (0, react_1.useState)(false);
    const closeAutocomplete = (0, react_1.useCallback)(() => setAutocompleteState(false), []);
    const createChooseOptionHandler = (value) => () => {
        var _a;
        handleChange === null || handleChange === void 0 ? void 0 : handleChange(value);
        closeAutocomplete();
        (_a = refInput.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const onClickHandler = () => {
        const shouldOpenAutocomplete = expandAutocompleteOnClick && autocompleteOptions.length > 0;
        shouldOpenAutocomplete && setAutocompleteState(true);
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    (0, outside_click_hooks_1.useOutsideClick)(() => { var _a; return ((_a = refContainer.current) === null || _a === void 0 ? void 0 : _a.querySelector(".c-text-field__autocomplete-content")) || null; }, closeAutocomplete, [closeAutocomplete]);
    return (react_1.default.createElement("div", { ref: refContainer, className: "c-text-field" },
        label && (react_1.default.createElement("label", { htmlFor: name, className: "c-text-field__label" }, label)),
        react_1.default.createElement("div", { className: "c-text-field__input-container" },
            react_1.default.createElement("input", { ref: refInput, onChange: onChange, onClick: onClickHandler, value: value, name: name, placeholder: placeholder, className: `c-text-field__input ${className}` }),
            react_1.default.createElement("div", { className: "c-text-field__autocomplete" },
                react_1.default.createElement(react_collapse_1.UnmountClosed, { isOpened: isAutocompleteOpened && autocompleteOptions.length > 0 },
                    react_1.default.createElement("div", { className: "c-text-field__autocomplete-content" }, autocompleteOptions.map((option) => (react_1.default.createElement("div", { onClick: createChooseOptionHandler(option), key: "option", className: "c-text-field__autocomplete-item" }, option))))))),
        error && react_1.default.createElement("div", { className: "c-text-field__validation" }, error)));
};
exports.TextInput = TextInput;
