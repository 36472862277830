"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFlags = void 0;
const react_1 = require("react");
const useFlags = (initialState) => {
    const [flagMap, setFlagMap] = (0, react_1.useState)(initialState);
    const setter = (key, value) => setFlagMap(Object.assign(Object.assign({}, flagMap), { [key]: value === undefined ? !flagMap[key] : value }));
    return {
        flags: flagMap,
        toggleFlag: setter,
        createToggleFlagHandler: (key, value) => () => setter(key, value),
    };
};
exports.useFlags = useFlags;
