"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVENTS = exports.ResponseCodes = exports.API_ROUTES = void 0;
var routes_1 = require("./routes");
Object.defineProperty(exports, "API_ROUTES", { enumerable: true, get: function () { return routes_1.API_ROUTES; } });
var codes_1 = require("./codes");
Object.defineProperty(exports, "ResponseCodes", { enumerable: true, get: function () { return codes_1.ResponseCodes; } });
exports.EVENTS = {
    WAIT_BLOCK_EVENT: "WAIT_BLOCK_EVENT",
};
