"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFileLoader = void 0;
const react_1 = require("react");
const useFileLoader = (file) => {
    const [fileData, setFileData] = (0, react_1.useState)("");
    (0, react_1.useMemo)(() => {
        const reader = new FileReader();
        reader.addEventListener("load", () => { var _a; return setFileData(((_a = reader.result) === null || _a === void 0 ? void 0 : _a.toString()) || ""); }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
        else {
            setFileData("");
        }
    }, [file]);
    return fileData;
};
exports.useFileLoader = useFileLoader;
