"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LotteryPage = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const core_1 = require("@web3-react/core");
const translation_hooks_1 = require("@hooks/translation.hooks");
const button_1 = require("@shared-modules/components/button");
const lottery_card_1 = require("@components/lottery-card");
const routing_hooks_1 = require("@hooks/routing.hooks");
const http_1 = require("@utils/http");
const token_hooks_1 = require("@hooks/token.hooks");
const loader_1 = require("@shared-modules/components/loader");
require("./styles.scss");
const stub_cards_1 = require("@components/stub-cards");
const SECTIONS = [
    { value: "running", label: "Running" },
    { value: "myTickets", label: "My Tickets" },
];
const LotteryPage = (props) => {
    const { account } = (0, core_1.useWeb3React)();
    const history = (0, react_router_dom_1.useHistory)();
    const commonTranslator = (0, translation_hooks_1.useCommonTranslation)();
    const { section = "running" } = (0, http_1.parseQuery)(history.location.search);
    const [activeSection, setActiveSection] = React.useState(section);
    const handleSetSection = (name) => () => {
        setActiveSection(name);
    };
    const filteredSection = React.useMemo(() => (Boolean(account) ? SECTIONS : SECTIONS.filter((item) => item.value !== "myTickets")), [account]);
    const { list: lotteries, nextPage, isLastPage, isFirstLoadCompleted, } = (0, token_hooks_1.useTokenListByFilter)({
        types: ["lottery"],
        isOnlyActive: activeSection === "running",
        participator: activeSection === "myTickets" ? account || undefined : undefined,
    });
    const getTokenPageUrl = (tokenId) => `/nft/token/${tokenId}`;
    const createRedirectToTokenPage = (0, routing_hooks_1.useRedirectToHandler)(getTokenPageUrl);
    return (React.createElement("div", { className: "p-lottery" },
        React.createElement("div", { className: "p-lottery__header" },
            React.createElement("div", { className: "p-lottery__section-select" }, filteredSection.map((buttonData) => (React.createElement(button_1.Button, { key: buttonData.label, type: activeSection === buttonData.value ? "gray" : "transparent", onClick: handleSetSection(buttonData.value) }, buttonData.label))))),
        React.createElement("div", { className: "p-lottery__grid" },
            isFirstLoadCompleted &&
                lotteries.map((lottery) => {
                    return (React.createElement(lottery_card_1.LotteryCard, { key: lottery.tokenId, lottery: lottery, onClick: createRedirectToTokenPage(lottery.tokenId), tokenPageUrl: getTokenPageUrl(lottery.tokenId) }));
                }),
            React.createElement(stub_cards_1.StubCards, null)),
        !isFirstLoadCompleted && (React.createElement("div", { className: "p-token-explorer-page__loader" },
            React.createElement(loader_1.Loader, { scale: 1 }))),
        !isLastPage && (React.createElement("div", { className: "p-lottery__button" },
            React.createElement(button_1.Button, { type: "thirtery", onClick: nextPage }, commonTranslator("loadMoreButton"))))));
};
exports.LotteryPage = LotteryPage;
exports.default = exports.LotteryPage;
