"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseError = void 0;
class BaseError extends Error {
    constructor(message, context, parentError) {
        super(message);
        this.createCopyOfError = (error) => {
            let errorCopy;
            if (error instanceof Error) {
                const errorProps = Object.getOwnPropertyNames(error);
                errorCopy = errorProps.reduce((errorAgregator, prop) => {
                    errorAgregator[prop] = error[prop];
                    return errorAgregator;
                }, {});
            }
            return errorCopy;
        };
        if (context instanceof Error) {
            this.parent = context && this.createCopyOfError(context);
        }
        else {
            this.errorContext = context;
        }
        this.parent = parentError && this.createCopyOfError(parentError);
    }
}
exports.BaseError = BaseError;
