"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInterval = void 0;
const react_1 = require("react");
const getCurrentSeconds = () => +new Date() / 1000;
const initTimestamp = getCurrentSeconds();
const useInterval = (interval) => {
    const [currentTimestamp, setCurrentTimestamp] = (0, react_1.useState)(getCurrentSeconds());
    (0, react_1.useEffect)(() => {
        const h = setInterval(() => {
            setCurrentTimestamp(getCurrentSeconds());
        }, interval);
        return () => clearInterval(h);
    }, []);
    return [currentTimestamp, initTimestamp];
};
exports.useInterval = useInterval;
