"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecentList = void 0;
const React = __importStar(require("react"));
const translation_hooks_1 = require("@hooks/translation.hooks");
const token_hooks_1 = require("@hooks/token.hooks");
const routing_hooks_1 = require("@hooks/routing.hooks");
const icons_1 = require("@shared-modules/components/icons");
const nft_token_card_list_1 = require("@components/nft-token-card-list");
const stub_cards_1 = require("@components/stub-cards");
const http_1 = require("@utils/http");
require("./styles.scss");
const RecentList = () => {
    const exploreRecentlyRedirect = (0, routing_hooks_1.useRedirectTo)("/explore" + (0, http_1.parseToUrlQuery)({ sorting: "desc", sortingProperty: "created" }), true);
    const { list: recentTokens } = (0, token_hooks_1.useTokenListByFilter)({
        sortingProperty: "created",
        sorting: "desc",
    }, { size: 12 });
    const translator = (0, translation_hooks_1.usePagesTranslation)("mainPage.recentList");
    return (React.createElement("div", { className: "c-main-page-recent-list" },
        React.createElement("div", { className: "c-main-page-recent-list__info" },
            React.createElement("div", { className: "c-main-page-recent-list__title" }, translator("title")),
            React.createElement("div", { className: "c-main-page-recent-list__description" }, translator("description")),
            React.createElement("div", { onClick: exploreRecentlyRedirect, className: "c-main-page-recent-list__link" },
                translator("moreLink"),
                " ",
                React.createElement(icons_1.ArrowRight, { color: "red" }))),
        React.createElement("div", { className: "c-main-page-recent-list__list" },
            React.createElement(nft_token_card_list_1.NFTTokenCardList, { list: recentTokens }),
            React.createElement(stub_cards_1.StubCards, null))));
};
exports.RecentList = RecentList;
exports.default = exports.RecentList;
